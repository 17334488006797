import React, { useCallback, useEffect, useState } from "react";
import addIcon from "../../../assets/Icons/add_pupple.svg";
import TrashRed from "../../../assets/Icons/trash-icon-red.png";
import CopyIcon from "../../../assets/Icons/copy_icon.svg";
import EditIcon from "../../../assets/Icons/edit_white_icon.png";
import ProfileG from "../../../assets/Icons/ProfileG.png";
import Searchpurple from "../../../assets/Icons/searchPurple.png";
import Arrow from "../../../assets/Icons/ArrowUp.png";
import tick from "../../../assets/Icons/Vector_pupple.png";
import "../../../styles/client/clinic/Services.scss";
import Flag1 from "../../../assets/Icons/flag1.png";
import Flag2 from "../../../assets/Icons/flag2.png";
import Flag3 from "../../../assets/Icons/flag3.png";
import Flag4 from "../../../assets/Icons/flag4.png";
import { ReactComponent as AddRed } from "../../../assets/Icons/AddRed.svg";
import "../../../styles/client/common/TimeTable.scss";
import {
  AXIOS_DELETE,
  AXIOS_GET,
  AXIOS_POST,
  AXIOS_PUT,
} from "../../../config/axios";
import {
  DELETE_SINGLE_PROVIDER_URL,
  GET_PROVIDERS_CLINIC_PROVIDER_URL,
  GET_SERVICES_URL,
  GET_SINGLE_PROVIDER_URL,
  POST_PROVIDERS_URL,
  POST_PROVIDER_DUPLICATE_URL,
  POST_PROVIDER_PIC_URL,
  UPDATE_PROVIDERS_URL,
} from "../../../helper/Url";
import GridTimeTable from "./Timetable/Vet/GridTimeTable";
import TimeTableSettings from "./Timetable/Vet/Components/TimeTableSettings";
import SuccessToast from "../../../helper/Message/SuccessToast";
import ErrorToast from "../../../helper/Message/ErrorToast";
import SuccessError from "../../../helper/Message/SuccessError";
import { ToastContainer } from "react-toastify";
import { get, set, useForm } from "react-hook-form";
import { Base_Url } from "../../../config/variables";
import Loader from "../../../helper/Loader/Loader";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useBlocker, useNavigate } from "react-router-dom";
import { Box, Modal } from "@mui/material";
import PhoneField from "../../common/PhoneField";

// 1 style for the modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: 5,
  borderColor: 'primary.main',
  boxShadow: 24,
  color:"black",
  p: 4,
};


const Veterinary = () => {
  const [tab, setTab] = useState({ calendar: true, settings: false });
  const [Showform, setShowform] = useState(false);
  const [vetlist, setvetlist] = useState([]);
  const [vetdata, setvetdata] = useState([]);
  const [serviceslist, setserviceslist] = useState([]);
  const clinic_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("user_token");
  const [addFormShow, setaddFormShow] = useState(false);
  const [services, setservices] = useState([]);
  const [language, setlanguage] = useState([]);
  const [base64String, setbase64String] = useState("");
  const [loader, setloader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [view1, setview1] = useState(false);
  const [view2, setview2] = useState(true);
  const [view3, setview3] = useState(true);
  const [view4, setview4] = useState(true);
  const [getvetLoader, setgetvetLoader] = useState(false);
  const [activeId, setactiveId] = useState("");
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  // 2 states to check if the form is saved or not
  const [datasaved, setDatasaved] = useState(true);
  const [editData, seteditData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    address: "",
    email: "",
    civilStatus: "",
    title: "",
    language: ["English"],
    services: [],
  });

  const [newData, setnewData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    address: "",
    email: "",
    civilStatus: "",
    title: "",
    language: [],
    services: [],
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  // Get all providers
  const getvets = () => {
    setgetvetLoader(true);
    AXIOS_GET(GET_PROVIDERS_CLINIC_PROVIDER_URL + `${clinic_id}`, token)
      .then((res) => {
        setgetvetLoader(false);
        setvetlist(res.data);
      })
      .catch((error) => {
        setgetvetLoader(false);
        console.log("Can't get Services : ", error.message);
      });
    getservices();
  };
  // handle service checkbox
  const handleCheckboxChangeForServices = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setservices((pre) => [...pre, value]);
    } else if (!checked) {
      setservices((pre) => {
        return [...pre.filter((item) => item !== value)];
      });
    }
  };
  // handle language checkbox
  const handleCheckboxChangeForLanguage = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setlanguage((pre) => [...pre, value]);
    } else if (!checked) {
      setlanguage((pre) => {
        return [...pre.filter((item) => item !== value)];
      });
    }
  };

  // Get single provider
  const getsinglevets = (id) => {
    setview1(false);
    setview2(true);
    setview3(true);
    setview4(true);
    setactiveId(id);
    setservices([]);
    setlanguage([]);
    setbase64String("");
    AXIOS_GET(GET_SINGLE_PROVIDER_URL + `${id}`, token)
      .then((res) => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setvetdata(res.data);
        setPhone(res.data.phone);
        seteditData({
          ...editData,
          firstname: res.data.firstname,
          lastname: res.data.lastname,
          phone: res.data.phone,
          address: res.data.address,
          email: res.data.email,
          civilStatus: res.data.civilStatus,
          title: res.data.title,
          language: res.data.language,
          services: res.data.services,
        });
        setservices(res.data.services.map((item) => item._id));
        setlanguage(res.data.language);
        setaddFormShow(false);
        setShowform(true);
      })
      .catch((error) => {
        console.log("Can't get Vet data : ", error.message);
      });
    getservices();
  };

  const formToadd = () => {
    setservices([]);
    setlanguage([]);
    setShowform(false);
    setactiveId("");
    setbase64String("");
    setPhone("");
    setaddFormShow(true);
  };
  const hideforms = () => {
    setaddFormShow(false);
  };

  // Get all services
  const getservices = () => {
    AXIOS_GET(GET_SERVICES_URL, token)
      .then((res) => {
        setserviceslist(res.data);
      })
      .catch((error) => {
        console.log("Can't get Services : ", error.message);
      });
  };

  // delete provider
  const deleteProvider = (id) => {
    AXIOS_DELETE(DELETE_SINGLE_PROVIDER_URL + `${id}`, "", token)
      .then(() => {
        setShowform(false);
        setaddFormShow(false);
        getvets();
        SuccessToast("Vétérinaire supprimé avec succès");
      })
      .catch((error) => {
        ErrorToast(error);
      });
  };
  const duplicateProvider = (id) => {
    AXIOS_POST(POST_PROVIDER_DUPLICATE_URL + `${id}`, null, token)
      .then((res) => {
        SuccessToast("Vétérinaire copié avec succès");
        getvets();
        setTimeout(() => {
          getsinglevets(res.data._id);
          setview2(false);
          setview3(false);
          setview4(false);
        }, 300);
      })
      .catch((error) => {
        ErrorToast(error);
      });
  };
  // Edit provider
  const editProvider = (id) => {
    if (editData.firstname === "") {
      ErrorToast("Veuillez remplir le champ Prénom");
    } else if (editData.lastname === "") {
      ErrorToast("Veuillez remplir le champ Nom");
    } else {
      editData.services = services;
      editData.language = language;
      editData.phone = phone;
      setloader(true);
      AXIOS_PUT(UPDATE_PROVIDERS_URL + `${id}`, editData, token)
        .then((res) => {
          setloader(false);
          getvets();
          SuccessToast("Vétérinaire modifié avec succès");
        })
        .catch((error) => {
          setloader(false);
          ErrorToast(error);
        });
    }
  };

  // Register provider
  const registerProvider = () => {
    if (newData.firstname === "") {
      ErrorToast("Veuillez remplir le champ Prénom");
    } else if (newData.lastname === "") {
      ErrorToast("Veuillez remplir le champ Nom");
    } else {
      newData.services = services;
      newData.language = language;
      newData.phone = phone;
      setloader(true);
      AXIOS_POST(POST_PROVIDERS_URL, newData, token)
        .then((res) => {
          setloader(false);
          

          // Convert base64 string to Blob
          if (base64String.length > 0) {
            var byteCharacters = atob(base64String.split(",")[1]);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], { type: "image/png" });

            // Create FormData object and append the Blob
            var formData = new FormData();
            formData.append("file", blob, "filename.png");
          }else{
            getvets();
          }

          AXIOS_POST(POST_PROVIDER_PIC_URL + `${res.data._id}`, formData, token)
            .then((res) => {})
            .catch((error) => {});
          setnewData({
            firstname: "",
            lastname: "",
            phone: "",
            address: "",
            email: "",
            civilStatus: "",
            title: "",
            language: [],
            services: [],
          });
          setlanguage([]);
          setservices([]);
          SuccessToast("Vétérinaire ajouté avec succès");
          setTimeout(() => {
            getvets();
            getsinglevets(res.data._id);
            setview2(false);
            setview3(false);
            setview4(false);
          }, 2000);
        })
        .catch((error) => {
          setloader(false);
          ErrorToast(error);
        });
    }
  };

  // add pictures
  const addPicture = (e) => {
    const file = e.target.files[0];
    let reader = new FileReader(); // Create a new FileReader object
    reader.onloadend = function () {
      // 'result' contains the base64 data of the uploaded file
      setbase64String(reader.result);
    };

    // Read the file as data URL (base64 format)
    reader.readAsDataURL(file);
  };

  const editPicture = (id, e) => {
    const file = e.target.files[0];
    const formdata = new FormData();
    formdata.append("file", file);
    let reader = new FileReader(); // Create a new FileReader object
    reader.onloadend = function () {
      // 'result' contains the base64 data of the uploaded file
      setbase64String(reader.result);
    };
    // Read the file as data URL (base64 format)
    reader.readAsDataURL(file);
    AXIOS_POST(POST_PROVIDER_PIC_URL + `${id}`, formdata, token)
      .then((res) => {
        SuccessToast("Photo modifiée avec succès");
        getvets();
      })
      .catch((error) => {
        ErrorToast(error);
      });
  };

  useEffect(() => {
    getvets();
  }, []);

  // get single provider on page load
  useEffect(() => {
    if (localStorage.getItem("vet_click_id")) {
      getsinglevets(localStorage.getItem("vet_click_id"));
    }
  }, [localStorage.getItem("vet_click_id")]);

   // 3.  watch for changes in the new form

   useEffect(() => {
    if (newData.firstname !== "" && newData.lastname !== ""  ) {
      setDatasaved(false);
    } else {
      setDatasaved(true);
    }
  }, [newData]);

  // 4. blocker

  let shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) =>
    {
      return !datasaved && currentLocation.pathname !== nextLocation.pathname
    },
    [datasaved]
  ); 
  let blocker = useBlocker(shouldBlock);

  // 5. Reset the blocker if the user cleans the form
  useEffect(() => {
    if (blocker.state === "blocked" && datasaved) {
      blocker.reset();
    }
  }, [blocker, datasaved]);

  const saveVetInfo = () =>{
     registerProvider();
     blocker.proceed();
  }

  return (
    <div className="content-container padding-special">
      <ToastContainer />
      {blocker.state === "blocked"   &&
        <Modal open="true">
          <Box sx={style}>
              <div>
               Souhaitez-vous sauvegarder vos modifications avant de quitter cette page ?
              </div>
              <div className="info-actio-btn">
              <button  onClick={() => blocker.reset()}>Non</button>
              <button onClick={saveVetInfo} >Oui</button>
              </div>
            </Box>
        
        </Modal>
      }
      <h1 className="welcome-title">
        Bienvenue <br />
        <span className="text_purple">Gestion des </span> vétérinaires
      </h1>
      <br />
      <div className="services-wrapper">
        <div className="service_list">
          <h1 className="page-name sm-page-name top-25 text_purple">
            Les vétérinaires
          </h1>
          <p className="remarks strong">
          Vous pouvez aussi retrouver le détails et modifier la fiche de chaque collaborateur en cliquant sur sa photo.
          </p>
          <div className="page-image-container-page">
            {vetlist.length > 0
              ? vetlist.map((vet) => (
                  <div
                    className="pet-data"
                    onClick={() => getsinglevets(vet._id)}
                  >
                    <div
                      className={
                        activeId == vet._id
                          ? "activer_border img-holder"
                          : "img-holder"
                      }
                    >
                      {/* purple border */}
                      <img
                        className="border-2 border-[#8B7BCB] rounded-full"
                        src={
                          vet.picture.includes("http")
                            ? vet.picture
                            : Base_Url + `/` + vet.picture
                        }
                        alt=""
                      />
                    </div>
                    <span className="text_purple" style={{ cursor: "pointer" }}>
                      {vet.firstname} {vet.lastname}
                    </span>
                  </div>
                ))
              : getvetLoader && (
                  <div className="pet-data">
                    <div className="img-holder">
                      <Loader color="#8b7bcb"></Loader>
                    </div>
                  </div>
                )}

            <div className="pet-data" onClick={formToadd}>
              <div className="img-holder">
                <img src={addIcon} className="addIcon" alt="" />
              </div>
              <span className="text_purple">Ajouter un vétérinaire</span>
            </div>
          </div>
        </div>
        {Showform ? (
          <div className="service_form_container border_purple border_purple">
            {view1 ? (
              <div
                onClick={() => setview1(false)}
                className="sub-nondetail"
                style={{ background: "white" }}
              >
                <div className="img_container">
                  <img src={ProfileG} alt="" />
                  <span></span>
                </div>
                <ExpandMoreIcon />
              </div>
            ) : (
              <div className="sub-container">
                <div
                  className="create-service except_class border_none"
                  style={{ borderRadius: "5px" }}
                >
                  <div
                    className="vet_attach_info"
                    onClick={() => setview1(true)}
                    style={{ width: "100%", cursor: "pointer" }}
                  >
                    <div className="vet_img">
                      <img
                        src={
                          base64String.length > 0
                            ? base64String
                            : vetdata.picture.includes("http")
                            ? vetdata.picture
                            : Base_Url + `/` + vetdata.picture
                        }
                        alt=""
                      />
                    </div>
                    <div className="h4 text_purple">
                      {vetdata.firstname} {vetdata.lastname}
                    </div>
                  </div>
                  <div className="title_action">
                    <button
                      className="vet"
                      onClick={() => {
                        setview1(!true);
                        setview2(!true);
                        setview3(!true);
                        setview4(!true);
                      }}
                    >
                      <div className="editIcon">
                        <img src={EditIcon} />
                      </div>
                    </button>
                    <button
                      className="vet"
                      onClick={() => duplicateProvider(vetdata._id)}
                      style={{ transform: "scale(1.4)" }}
                    >
                      <img src={CopyIcon} />
                    </button>
                    <button
                      className="vet"
                      onClick={() => deleteProvider(vetdata._id)}
                      style={{ transform: "scale(1.4)" }}
                    >
                      <img src={TrashRed} />
                    </button>
                  </div>
                </div>
              </div>
            )}

            {view2 ? (
              <div
                onClick={() => setview2(false)}
                className="sub-nondetail"
                style={{ borderBottom: "none" }}
              >
                Informations <ExpandMoreIcon />
              </div>
            ) : (
              <div className="sub-container">
                <div className="create-service bg_purple_light border_purple border_none">
                  <div className="title_service">
                    <h3 className="text_purple">Informations</h3>
                  </div>
                  <div className="title_action">
                    <button
                      onClick={() => setShowform(false)}
                      className="strong main-color text_purple capitalize annule-special"
                    >
                      Annuler
                    </button>
                    {loader ? (
                      <button className="active_btn bg_purple_strong">
                        <Loader color="white" />
                      </button>
                    ) : (
                      <button
                        className="active_btn bg_purple_strong"
                        onClick={() => editProvider(vetdata._id)}
                      >
                        Enregistrer
                      </button>
                    )}

                    <img src={Arrow} onClick={() => setview2(true)} alt="" />
                  </div>
                </div>
                <div className="service-form">
                  <div className="page-image-container">
                    <div className="pet-data">
                      <label className="img-holder" htmlFor="vet-photo">
                        <img src={addIcon} className="addIcon" alt="" />
                        <input
                          required
                          className="border_purple"
                          type="file"
                          hidden
                          id="vet-photo"
                          onChange={(e) => editPicture(vetdata._id, e)}
                        />
                      </label>
                      <label
                        className="add-label text_purple"
                        htmlFor="vet-photo"
                      >
                        {" "}
                        Ajouter une photo
                      </label>
                    </div>
                  </div>

                  <div className="change-form">
                    <form className="">
                      {/* <h1 className="form-subtitle">Informations sur le vétérinaire</h1> */}
                      <div className="flex-container gap-10">
                        <div className="input-container input-container-2">
                          <label htmlFor="" className="text_purple">
                            Prénom*
                          </label>
                          <input
                            required
                            className="border_purple"
                            type="text"
                            placeholder="Prénom*"
                            value={editData.lastname}
                            onChange={(e) =>
                              seteditData({
                                ...editData,
                                lastname: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="input-container input-container-2">
                          <label htmlFor="" className="text_purple">
                            Nom*
                          </label>
                          <input
                            required
                            className="border_purple"
                            type="text"
                            placeholder="Nom*"
                            value={editData.firstname}
                            onChange={(e) =>
                              seteditData({
                                ...editData,
                                firstname: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="flex-container gap-10">
                        <div className="input-container input-container-2">
                          <label htmlFor="" className="text_purple">
                            Civilité*
                          </label>
                          <select
                            required
                            className="border_purple"
                            value={editData.civilStatus}
                            onChange={(e) =>
                              seteditData({
                                ...editData,
                                civilStatus: e.target.value,
                              })
                            }
                          >
                            <option value="" selected disabled>
                  Civilité*
                </option>
                <option value="Mr">Monsieur</option>
                <option value="Mrs">Madame</option>
                <option value="Ms">Mademoiselle</option>
              </select>
                        </div>
                        <div className="input-container input-container-2">
                          <label htmlFor="" className="text_purple">
                            Titre
                          </label>
                          <select
                            required
                            className="border_purple"
                            value={editData.title}
                            onChange={(e) =>
                              seteditData({
                                ...editData,
                                title: e.target.value,
                              })
                            }
                          >
                            <option selected>
                              Titre
                            </option>
                            <option value="Dr">Dr.</option>
                            <option value="Mme">Mme.</option>
                            <option value="Mlle">Mlle.</option>
                            <option value="M">M.</option>
                            <option value="Me">Me.</option>
                            <option value="Pr">Pr.</option>
                          </select>
                        </div>
                      </div>
                      <div className="flex-container gap-10">
                        <div className="input-container input-container-2">
                          <label htmlFor="" className="text_purple">
                            E-mail
                          </label>
                          <input
                            className="border_purple"
                            type="email"
                            placeholder="E-mail"
                            value={editData.email}
                            onChange={(e) =>
                              seteditData({
                                ...editData,
                                email: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="input-container input-container-2">
                          <label htmlFor="" className="text_purple">
                            Téléphone
                          </label>
                          {/* <div className="input-icon border_purple flex-container">
                            <AddRed />
                            <input
                              required
                              className="border_purple"
                              type="text"
                              placeholder="Téléphone"
                              value={editData.phone}
                              onChange={(e) =>
                                seteditData({
                                  ...editData,
                                  phone: e.target.value,
                                })
                              }
                            /> 
                          </div> */}
                           <PhoneField 
                     containerStyle={{ width:"100%", height:"52px" }}
                     buttonStyle={{ borderColor:"#8b7bcb" }}  
                     inputStyle={{ width:"100%",height:"52px",borderColor:"#8b7bcb", paddingLeft:"36px" }}
                     placeholder="Votre téléphone"  
                     telephone={phone}
                     settelephone={setPhone}
                />
                        </div>
                      </div>
                      <div className="label-check">
                        {/* <span className="text-data">
              Espèce(s) d’animal autorisée(s) pour ce service
            </span> */}
                        <div className="checkbox_group">
                          <span className="text_purple strong">
                            Langue(s) parlée(s)
                          </span>
                          <label className="checkbox_wrapper" htmlFor="flag1">
                            <div className="pet_checkbox"></div>
                            <div className="flag">
                              <img src={Flag1} alt="" />
                              <span>Français</span>
                            </div>
                            <input
                              required
                              type="checkbox"
                              className="pet_hidden_check"
                              value="French"
                              id="flag1"
                              onChange={handleCheckboxChangeForLanguage}
                              hidden
                            />
                            {language?.includes("French") && (
                              <img src={tick} alt="" />
                            )}
                          </label>
                          <label className="checkbox_wrapper" htmlFor="flag2">
                            <div className="pet_checkbox"></div>
                            <div className="flag">
                              <img src={Flag2} alt="" />
                              <span>Anglais</span>
                            </div>
                            <input
                              required
                              type="checkbox"
                              className="pet_hidden_check"
                              id="flag2"
                              value="English"
                              onChange={handleCheckboxChangeForLanguage}
                              hidden
                            />
                            {language?.includes("English") && (
                              <img src={tick} alt="" />
                            )}
                          </label>
                          <label className="checkbox_wrapper" htmlFor="flag3">
                            <div className="pet_checkbox"></div>
                            <div className="flag">
                              <img src={Flag3} alt="" />
                              <span>Allemand</span>
                            </div>
                            <input
                              required
                              type="checkbox"
                              className="pet_hidden_check"
                              id="flag3"
                              value="German"
                              onChange={handleCheckboxChangeForLanguage}
                              hidden
                            />
                            {language?.includes("German") && (
                              <img src={tick} alt="" />
                            )}
                          </label>
                          <label className="checkbox_wrapper" htmlFor="flag4">
                            <div className="pet_checkbox"></div>
                            <div className="flag">
                              <img src={Flag4} alt="" />
                              <span>Italien</span>
                            </div>
                            <input
                              required
                              type="checkbox"
                              className="pet_hidden_check"
                              id="flag4"
                              value="Italian"
                              onChange={handleCheckboxChangeForLanguage}
                              hidden
                            />
                            {language?.includes("Italian") && (
                              <img src={tick} alt="" />
                            )}
                          </label>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}

            {view3 ? (
              <div onClick={() => setview3(false)} className="sub-nondetail">
                Horaires <ExpandMoreIcon />
              </div>
            ) : (
              <div className="sub-container">
                <div className="create-service bg_purple_light border_purple border_none">
                  <div className="title_service">
                    <h3 className="text_purple">Horaires</h3>
                  </div>
                  <div className="title_action">
                    <button className="strong main-color text_purple capitalize annule-special">
                      Annuler
                    </button>
                    {loader ? (
                      <button className="active_btn bg_purple_strong">
                        <Loader color="white" />
                      </button>
                    ) : (
                      <button
                        className="active_btn bg_purple_strong"
                        onClick={() => editProvider(vetdata._id)}
                      >
                        Enregistrer
                      </button>
                    )}
                    <img src={Arrow} onClick={() => setview3(true)} alt="" />
                  </div>
                </div>
                <div className="time_table_container">
                  <div className="time_table">
                    <div className="timetable_action">
                      <div
                        onClick={() =>
                          setTab({ calendar: true, settings: false })
                        }
                        className={tab.calendar && "active"}
                      >
                        Horaires de la semaine
                      </div>
                      <div
                        onClick={() =>
                          setTab({ calendar: false, settings: true })
                        }
                        className={tab.settings && "active"}
                      >
                        Horaires spéciaux
                      </div>
                    </div>
                    {tab.settings ? (
                      <TimeTableSettings entId={vetdata._id} />
                    ) : (
                      <GridTimeTable providerId={vetdata._id} />
                    )}
                  </div>
                </div>
              </div>
            )}

            {view4 ? (
              <div onClick={() => setview4(false)} className="sub-nondetail">
                Services attachés à ce vétérinaire <ExpandMoreIcon />
              </div>
            ) : (
              <div className="sub-container">
                <div className="create-service bg_purple_light border_purple border_none">
                  <div className="title_service">
                    <h3 className="text_purple">
                    Services attachés à ce vétérinaire
                    </h3>
                  </div>
                  <div className="title_action">
                    <button className="strong main-color text_purple capitalize annule-special">
                      Annuler
                    </button>
                    {loader ? (
                      <button className="active_btn bg_purple_strong">
                        <Loader color="white" />
                      </button>
                    ) : (
                      <button
                        className="active_btn bg_purple_strong"
                        onClick={() => editProvider(vetdata._id)}
                      >
                        Enregistrer
                      </button>
                    )}
                    <img src={Arrow} onClick={() => setview4(true)} alt="" />
                  </div>
                </div>
                {serviceslist?.length>0 && 
                <div className="service-form">
                  <div className="search-bar border-purple">
                    <div className="container">
                      <input
                        type="text"
                        placeholder="Rechercher un service"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <img src={Searchpurple} alt="add" />
                    </div>
                  </div>
                </div>
                }

                {serviceslist?.length
                  ? serviceslist.map((serviceInfo, index) => {
                      // filter services
                      if (searchTerm == "") {
                        return (
                          <div className="service-form">
                            <div className="vet-list">
                              <div className="vet_attach_info">
                                <div className="vet_img">
                                  <img
                                    src={serviceInfo.picture[1]
                                      ? Base_Url + `/${serviceInfo.picture[1]}`
                                      : "https://media.istockphoto.com/id/1303362255/photo/young-happy-veterinary-nurse-smiling-while-playing-with-a-dog-high-quality-photo.jpg?s=612x612&w=0&k=20&c=_kIKIzB4GF5WDLCf2yK1XMQk9JtxoL9XPK_nJmTZ5JQ="}
                                    alt=""
                                  />
                                </div>
                                <div className="h4 text_purple">
                                  {serviceInfo.name}
                                </div>
                              </div>
                              <div className="vet_checker_info">
                                <div className="checkbox_group">
                                  <label
                                    className="checkbox_wrapper"
                                    htmlFor={`checkbox${5 + index * 2}`}
                                  >
                                    <div className="pet_checkbox"></div>
                                    <span></span>
                                    <input
                                      required
                                      type="checkbox"
                                      className="pet_hidden_check"
                                      value={serviceInfo._id}
                                      onChange={handleCheckboxChangeForServices}
                                      id={`checkbox${5 + index * 2}`}
                                      hidden
                                    />
                                    {services?.includes(serviceInfo?._id) && (
                                      <img src={tick} alt="" />
                                    )}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      } else if (
                        serviceInfo.name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return (
                          <div className="service-form">
                            <div className="vet-list">
                              <div className="vet_attach_info">
                                <div className="vet_img">
                                  <img
                                    src={serviceInfo.picture[1]
                                      ? Base_Url + `/${serviceInfo.picture[1]}`
                                      : "https://media.istockphoto.com/id/1303362255/photo/young-happy-veterinary-nurse-smiling-while-playing-with-a-dog-high-quality-photo.jpg?s=612x612&w=0&k=20&c=_kIKIzB4GF5WDLCf2yK1XMQk9JtxoL9XPK_nJmTZ5JQ="}
                                    alt=""
                                  />
                                </div>
                                <div className="h4 text_purple">
                                  {serviceInfo.name}
                                </div>
                              </div>
                              <div className="vet_checker_info">
                                <div className="checkbox_group">
                                  <label
                                    className="checkbox_wrapper"
                                    htmlFor={`checkbox${5 + index * 2}`}
                                  >
                                    <div className="pet_checkbox"></div>
                                    <span></span>
                                    <input
                                      required
                                      type="checkbox"
                                      className="pet_hidden_check"
                                      value={serviceInfo._id}
                                      onChange={handleCheckboxChangeForServices}
                                      id={`checkbox${5 + index * 2}`}
                                      hidden
                                    />
                                    {services?.includes(serviceInfo?._id) && (
                                      <img src={tick} alt="" />
                                    )}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })
                  : ''}
                  <div className="service-form assign-attrb">
                  <h3>Ajouter un service pour l'attribuer au vétérinaire. </h3>
                  <button onClick={()=>navigate('/clinic/dashboard/service/action')}>Ajouter un service</button>
                </div>
              </div>
            )}
            
          </div>
        ) : (
          ""
        )}

        {addFormShow ? (
          <div className="service_form_container border border-radius border_purple">
            <div className="create-service bg_purple_light border_purple border_none">
              <div className="title_service">
                <h3 className="text_purple">Informations</h3>
              </div>
              <div className="title_action">
                <button
                  onClick={hideforms}
                  className="strong main-color text_purple capitalize annule-special"
                >
                  Annuler
                </button>

                {loader ? (
                  <button className="active_btn bg_purple_strong">
                    <Loader color="white" />
                  </button>
                ) : (
                  <button
                    className="active_btn bg_purple_strong"
                    onClick={registerProvider}
                  >
                    Enregistrer
                  </button>
                )}
              </div>
            </div>
            <div className="service-form">
              <div className="page-image-container">
                <div className="pet-data">
                  <label className="img-holder" htmlFor="vet-photo">
                    {base64String.length > 0 ? (
                      <img src={base64String} className="full-image" />
                    ) : (
                      <img src={addIcon} className="addIcon" alt="" />
                    )}

                    <input
                      required
                      className="border_purple"
                      type="file"
                      hidden
                      id="vet-photo"
                      onChange={addPicture}
                    />
                  </label>
                  <label className="add-label text_purple" htmlFor="vet-photo">
                    Ajouter une photo
                  </label>
                </div>
              </div>

              <div className="change-form">
                <form className="">
                  {/* <h1 className="form-subtitle">Informations sur le vétérinaire</h1> */}
                  <div className="flex-container gap-10">
                    <div className="input-container input-container-2">
                      <label htmlFor="" className="text_purple">
                        Prénom*
                      </label>
                      <input
                        required
                        className="border_purple"
                        type="text"
                        placeholder="Prénom*"
                        value={newData.lastname}
                        onChange={(e) =>
                          setnewData({ ...newData, lastname: e.target.value })
                        }
                      />
                    </div>
                    <div className="input-container input-container-2">
                      <label htmlFor="" className="text_purple">
                        Nom*
                      </label>
                      <input
                        required
                        className="border_purple"
                        type="text"
                        placeholder="Nom*"
                        value={newData.firstname}
                        onChange={(e) =>
                          setnewData({ ...newData, firstname: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="flex-container gap-10">
                    <div className="input-container input-container-2">
                      <label htmlFor="" className="text_purple">
                        Civilité*
                      </label>
                      <select
                        required
                        className="border_purple"
                        value={newData.civilStatus}
                        onChange={(e) =>
                          setnewData({
                            ...newData,
                            civilStatus: e.target.value,
                          })
                        }
                      >
                        <option value="" selected disabled>
                  Civilité*
                </option>
                <option value="Mr">Monsieur</option>
                <option value="Mrs">Madame</option>
                <option value="Ms">Mademoiselle</option>
              </select>
                    </div>
                    <div className="input-container input-container-2">
                      <label htmlFor="" className="text_purple">
                        Titre
                      </label>
                      <select
                        required
                        className="border_purple"
                        value={newData.title}
                        onChange={(e) =>
                          setnewData({ ...newData, title: e.target.value })
                        }
                      >
                         <option selected>
                  Titre
                </option>
                <option value="Dr">Dr.</option>
                <option value="Mme">Mme.</option>
                <option value="Mlle">Mlle.</option>
                <option value="M">M.</option>
                <option value="Me">Me.</option>
                <option value="Pr">Pr.</option>
              </select>
                    </div>
                  </div>
                  <div className="flex-container gap-10">
                    <div className="input-container input-container-2">
                      <label htmlFor="" className="text_purple">
                        E-mail
                      </label>
                      <input
                        className="border_purple"
                        type="email"
                        placeholder="E-mail"
                        value={newData.email}
                        onChange={(e) =>
                          setnewData({ ...newData, email: e.target.value })
                        }
                      />
                    </div>
                    <div className="input-container input-container-2">
                      <label htmlFor="" className="text_purple">
                        Téléphone
                      </label>
                      {/* <div className="input-icon border_purple flex-container">
                        <AddRed />
                        <input
                          required
                          className="border_purple"
                          type="text"
                          placeholder="Téléphone"
                          value={newData.phone}
                          onChange={(e) =>
                            setnewData({ ...newData, phone: e.target.value })
                          }
                        />
                      </div> */}
                       <PhoneField 
                        containerStyle={{ width:"100%", height:"52px" }}
                        buttonStyle={{ borderColor:"#8b7bcb" }}  
                        inputStyle={{ width:"100%",height:"52px",borderColor:"#8b7bcb", paddingLeft:"36px" }}
                        placeholder="Votre téléphone"  
                        telephone={phone}
                        settelephone={setPhone}
                     />
                    </div>
                  </div>
                  <div className="label-check">
                    {/* <span className="text-data">
              Espèce(s) d’animal autorisée(s) pour ce service
            </span> */}
                    <div className="checkbox_group">
                      <span className="text_purple strong">
                        Langue(s) parlée(s)
                      </span>
                      <label className="checkbox_wrapper" htmlFor="flag1">
                        <div className="pet_checkbox"></div>
                        <div className="flag">
                          <img src={Flag1} alt="" />
                          <span>Français</span>
                        </div>
                        <input
                          required
                          type="checkbox"
                          className="pet_hidden_check"
                          value="French"
                          id="flag1"
                          onChange={handleCheckboxChangeForLanguage}
                          hidden
                        />
                        {language?.includes("French") && (
                          <img src={tick} alt="" />
                        )}
                      </label>
                      <label className="checkbox_wrapper" htmlFor="flag2">
                        <div className="pet_checkbox"></div>
                        <div className="flag">
                          <img src={Flag2} alt="" />
                          <span>Anglais</span>
                        </div>
                        <input
                          required
                          type="checkbox"
                          className="pet_hidden_check"
                          id="flag2"
                          value="English"
                          onChange={handleCheckboxChangeForLanguage}
                          hidden
                        />
                        {language?.includes("English") && (
                          <img src={tick} alt="" />
                        )}
                      </label>
                      <label className="checkbox_wrapper" htmlFor="flag3">
                        <div className="pet_checkbox"></div>
                        <div className="flag">
                          <img src={Flag3} alt="" />
                          <span>Allemand</span>
                        </div>
                        <input
                          required
                          type="checkbox"
                          className="pet_hidden_check"
                          id="flag3"
                          value="German"
                          onChange={handleCheckboxChangeForLanguage}
                          hidden
                        />
                        {language?.includes("German") && (
                          <img src={tick} alt="" />
                        )}
                      </label>
                      <label className="checkbox_wrapper" htmlFor="flag4">
                        <div className="pet_checkbox"></div>
                        <div className="flag">
                          <img src={Flag4} alt="" />
                          <span>Italien</span>
                        </div>
                        <input
                          required
                          type="checkbox"
                          className="pet_hidden_check"
                          id="flag4"
                          value="Italian"
                          onChange={handleCheckboxChangeForLanguage}
                          hidden
                        />
                        {language?.includes("Italian") && (
                          <img src={tick} alt="" />
                        )}
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Veterinary;
