import React from "react";
import { Base_Url } from "../../../../config/variables";

const NotActiveClinicInfo = ({ clinic }) => {
  // Function to capitalize the first letter of each word
  const capitalize = (text) => {
    return text.replace(/\b\w/g, char => char.toUpperCase());
  };

  return (
    <div className="w-full h-full flex items-start justify-between p-4 flex-wrap bg-white shadow-md rounded-lg">
      <div className="flex items-start" style={{ width: '50%' }}>
        <img
          src={
            clinic.picture.includes("http")
              ? clinic.picture
              : `${Base_Url}/${clinic.picture}`
          }
          alt="Clinic Logo"
          className="w-10 h-10 mr-3 rounded-full border-2 border-[#2CB5B2]"
        />
        <div className="text-left">
          <span className="text-lg font-bold">
            <span className="text-[#2CB5B2]">{clinic.facilityName}</span>
          </span>
        </div>
      </div>
      <div className="text-gray-600 text-sm text-left" style={{ width: '45%', paddingLeft: '5%' }}>
        <div>{capitalize(clinic.address.toLowerCase())}</div>
        <div>{clinic.postalCode} {capitalize(clinic.city.toLowerCase())}</div>
        <div></div>
      </div>
      {/* <button className="bg-teal-400 text-white py-1 px-3 rounded-md text-sm" style={{ width: '15%' }}>
        Voir profil
      </button> */}
    </div>
  );
};

export default NotActiveClinicInfo;
