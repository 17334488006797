import React from "react";

import TrashIconred from "../../../../assets/Icons/trash-icon-red.png";
import PersonIcon from "@mui/icons-material/Person";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import PetsIcon from "@mui/icons-material/Pets";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Base_Url } from "../../../../config/variables";

const RendezvousCard = ({ item, deleteAppointment }) => {
  const navigate = useNavigate();
  const [modifie, setVets] = useState([]);
  const [edit, editVets] = useState(false);

  const formatTime = (hour) => {
    const formattedHour = String(hour).padStart(2, "0");
    const formattedMinutes = "00";
    return `${formattedHour}:${formattedMinutes}`;
  };
  const dateToreadable = (date) => {
    const inputString = date.split("-")
    const year = inputString[0]
    const month = inputString[1]
    const day = inputString[2]
    return `${day}-${month}-${year}`
    } 

  const TimeDisplay = (hour) => {
    return formatTime(hour);
  };

  return (
    <div className="rendezvous_item">
      <div className="rendezvous_img">
        <div className="img_container">
          <img
            src={item?.pet?.picture ? Base_Url + `/${item.pet.picture}` : item?.species === "Chat" ? Base_Url + `/uploads/defaults/PNG/oneclic.vet-chat.png` : item?.species === "Chien" ? Base_Url + `/uploads/defaults/PNG/oneclic.vet-chien.png` : Base_Url + `/uploads/defaults/PNG/oneclic.vet-nac.png`}
            alt=""
          />
        </div>
        <span className="item_data">
          <PetsIcon /> {item?.species} -{" "}
          {item?.pet?.name ? item?.pet?.name : "N/A"}
        </span>
      </div>
      <div className="rendezvous_address">
        <span className="item_data" style={{ alignItems:"start" }}>
          <LocationOnIcon />
          {item?.clinic?.address} 
        </span>
        <span className="item_data" style={{ alignItems:"start" }}>
          {edit ? (
            <div className="changeitemvalue">
              <PersonIcon />
              <select name="" id="">
                <option value="">
                  {item?.provider?.firstname} {item?.provider?.lastname}
                </option>
                <option value=""></option>
              </select>
            </div>
          ) : (
            <>
              <PersonIcon />
               Dr. {" "}
              {item?.provider?.firstname} {item?.provider?.lastname}
            </>
          )}
        </span>
        {/* {edit == true ? <button onClick={()=>editVets(false)} >Enregistrer</button> : <button onClick={()=>editVets(true)}>Modifier </button>} */}
      </div>
      <div className="rendezvous_address">
        <span className="item_data">
          {edit ? (
            <div className="changeitemvalue">
              <CalendarMonthIcon />
              <input
                type="date"
                value={new Date(item?.date).toISOString().slice(0, 10)}
              />
            </div>
          ) : (
            <>
              <CalendarMonthIcon />{" "}
              {dateToreadable(new Date(item?.date).toISOString().slice(0, 10))}
            </>
          )}
        </span>
        <span className="item_data">
          {edit ? (
            <div className="changeitemvalue">
              <ScheduleIcon />
              <select name="" id="">
                <option value="">
                  <ScheduleIcon />
                  {TimeDisplay(item?.start?.hours)}
                </option>
              </select>
            </div>
          ) : (
            <>
              <ScheduleIcon />
              {TimeDisplay(item?.start?.hours)}
            </>
          )}
        </span>

        <span className="item_data">
          <div
            className="edititemvalue"
            onClick={() => deleteAppointment(item._id)}
          >
            <img src={TrashIconred} alt="" srcset="" />
            <span>Supprimer le rendez-vous</span>
          </div>
        </span>
      </div>
      <div className="rendezvous_action">
        {/* {
                item?.pet?
              <button onClick={()=>navigate(`/clinic/dashboard/animals/${item?.pet?._id}`)}>Lire la fiche de {item?.pet?.name}</button>
              :
              <button className="no-pet" style={{ color:"#2CB5B2", background:"white", border:"2px solid #2CB5B2", cursor:"none" }}>Pet is not registered</button>
              } */}
        <button
          className="no-pet"
          style={{
            color: "#2CB5B2",
            border: "2px solid #2CB5B2",
            cursor: "none",
          }}
          onClick={() => navigate("/search?clinicId=" + item?.clinic?._id)}
        >
          Prendre de nouveau rdv ici
        </button>
      </div>
    </div>
  );
};

export default RendezvousCard;
