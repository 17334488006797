import { useCallback, useEffect, useState } from "react";
import "../../../styles/client/clinic/Services.scss";
import TrashRed from "../../../assets/Icons/trash-icon-red.png";
import CopyIcon from "../../../assets/Icons/copy_icon.svg";
import EditIcon from "../../../assets/Icons/edit_icon.svg";
import Arrow from "../../../assets/Icons/ArrowUp.png";
import Add from "../../../assets/Icons/add_pupple.svg";
import Searchpurple from "../../../assets/Icons/searchPurple.png";
import tick from "../../../assets/Icons/Vector_pupple.png";
import "../../../styles/client/common/TimeTable.scss";
import AddIcon from "../../../assets/Icons/add_pupple.svg";
import {
  AXIOS_DELETE,
  AXIOS_GET,
  AXIOS_PATCH,
  AXIOS_POST,
  AXIOS_PUT,
} from "../../../config/axios";
import {
  DELETE_SERVICE_URL,
  GET_PROVIDERS_CLINIC_PROVIDER_URL,
  GET_PROVIDERS_URL,
  GET_SERVICES_URL,
  GET_SINGLE_SERVICE_URL,
  POST_SERVICE_DUPLICATE_URL,
  POST_SERVICE_PIC_URL,
  POST_SERVICE_URL,
  UPDATE_SERVICE_URL,
} from "../../../helper/Url";
import GridTimeTable from "./Timetable/Service/GridTimeTable";
import TimeTableSettings from "./Timetable/Service/Components/TimeTableSettings";
import ErrorToast from "../../../helper/Message/ErrorToast";
import { ToastContainer } from "react-toastify";
import SuccessToast from "../../../helper/Message/SuccessToast";
import { Base_Url } from "../../../config/variables";
import { set } from "react-hook-form";
import Loader from "../../../helper/Loader/Loader";
import { ArrowDownward } from "@mui/icons-material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useBlocker, useNavigate } from "react-router-dom";
import { Box, Modal } from "@mui/material";

// 1 style for the modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: 5,
  borderColor: 'primary.main',
  boxShadow: 24,
  color:"black",
  p: 4,
};

const Services = () => {
  const [tab, setTab] = useState({ calendar: true, settings: false });
  const [Showform, setShowform] = useState(false);
  const [serviceData, setserviceData] = useState([]);
  const [oneservice, setoneservice] = useState([]);
  const [addform, setaddform] = useState(false);
  const [vetlist, setvetlist] = useState([]);
  const clinic_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("user_token");
  const [petSpecies, setPetSpecies] = useState([]);
  const [providers, setProviders] = useState([]);
  const [view1, setView1] = useState(false);
  const [view2, setView2] = useState(true);
  const [view3, setView3] = useState(true);
  const [base64String, setbase64String] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeId, setActiveId] = useState("");
  const navigate = useNavigate();
  // 2 states to check if the form is saved or not
  const [datasaved, setDatasaved] = useState(true);
  const [getservicesLoader, setgetservicesLoader] = useState(false);

  const [newData, setnewData] = useState({
    name: "",
    duration: 0,
    description: "",
    preparationTime: 0,
    adjustmentTime: 0,
    petSpecies: [],
    hideDurationOnBooking: true,
    providers: [],
  });
  const [editData, seteditData] = useState({
    name: "",
    duration: 0,
    description: "",
    preparationTime: 0,
    adjustmentTime: 0,
    petSpecies: ["NAC"],
    hideDurationOnBooking: true,
    providers: [],
    picture: [],
    _id: 0,
  });

  const handleCheckboxChangeForPets = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setPetSpecies((pre) => [...pre, value]);
    } else if (!checked) {
      setPetSpecies((pre) => {
        return [...pre.filter((item) => item !== value)];
      });
    }
  };

  const handleCheckboxChangeForProviders = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setProviders((pre) => [...pre, value]);
    } else if (!checked) {
      setProviders((pre) => {
        return [...pre.filter((item) => item !== value)];
      });
    }
  };

  // Get all service
  const getservices = () => {
    setbase64String([]);
    setgetservicesLoader(true);
    AXIOS_GET(GET_SERVICES_URL, token)
      .then((res) => {
        setgetservicesLoader(false);
        setserviceData(res.data);
      })
      .catch((error) => {
        setgetservicesLoader(false);
        ErrorToast(error);
      });
    getvets();
  };

  // Get vets
  const getvets = () => {
    AXIOS_GET(GET_PROVIDERS_CLINIC_PROVIDER_URL + `${clinic_id}`, token)
      .then((res) => {
        setvetlist(res.data);
      })
      .catch((error) => {
        ErrorToast(error);
      });
  };

  // Get single service
  const getsingleservices = (id) => {
    setView2(true);
    setView3(true);
    setProviders([]);
    setPetSpecies([]);
    id &&
      AXIOS_GET(GET_SINGLE_SERVICE_URL + `${id}`, token)
        .then((res) => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          setoneservice(res.data);
          seteditData({
            ...editData,
            name: res.data.name,
            duration: res.data.duration,
            description: res.data.description,
            preparationTime: res.data.preparationTime,
            adjustmentTime: res.data.adjustmentTime,
            petSpecies: res.data.petSpecies,
            hideDurationOnBooking: res.data.hideDurationOnBooking,
            providers: res.data.providers,
            picture: res.data.picture,
            _id: res.data._id,
          });
          setPetSpecies(res.data.petSpecies);
          if (res.data.providers.length > 0) {
            setProviders(res.data.providers.map((item) => item._id));
          } else {
            setProviders([]);
          }
          setaddform(false);
          setShowform(true);
          setActiveId(id);
        })
        .catch((error) => {});
  };

  // add pictures
  const addPicture = (e) => {
    const file = e.target.files[0];
    let reader = new FileReader(); // Create a new FileReader object
    reader.onloadend = function () {
      // 'result' contains the base64 data of the uploaded file
      setbase64String([...base64String, reader.result]);
    };

    // Read the file as data URL (base64 format)
    reader.readAsDataURL(file);
  };

  const editPicture = (id, e) => {
    const file = e.target.files[0];
    const formdata = new FormData();
    formdata.append("file", file);
    let reader = new FileReader(); // Create a new FileReader object
    reader.onloadend = function () {
      // 'result' contains the base64 data of the uploaded file
      setbase64String(reader.result);
    };
    // Read the file as data URL (base64 format)
    reader.readAsDataURL(file);
    AXIOS_POST(POST_SERVICE_PIC_URL + `${id}`, formdata, token)
      .then((res) => {
        SuccessToast("Photo modifiée avec succès");
        getsingleservices(id);
      })
      .catch((error) => {
        ErrorToast(error);
      });
  };

  const formToadd = () => {
    setnewData({
      name: "",
      duration: 0,
      description: "",
      preparationTime: 0,
      adjustmentTime: 0,
      petSpecies: [],
      hideDurationOnBooking: true,
      providers: [],
    });
    setPetSpecies([]);
    setProviders([]);
    setShowform(false);
    setaddform(true);
    setbase64String([]);
  };
  const hideforms = () => {
    setaddform(false);
  };

  const deleteservice = (id) => {
    let isConfirm = window.confirm("Êtes-vous sûr de vouloir supprimer");
    if (isConfirm) {
      AXIOS_DELETE(DELETE_SERVICE_URL + `${id}`, "", token)
        .then((res) => {
          getservices();
          SuccessToast("Service supprimé avec succès");
        })
        .catch((error) => {
          ErrorToast(error);
        });
    }
  };

  // add service
  const addservice = () => {
    if (newData.name === "") {
      ErrorToast("Veuillez entrer le nom du service");
    }else if( newData.description === ""){
      ErrorToast("Veuillez entrer la description du service");
    } else {
    newData.petSpecies = petSpecies;
    newData.providers = providers;
    newData.duration = parseInt(newData.duration);
    newData.preparationTime = parseInt(newData.preparationTime);
    newData.adjustmentTime = parseInt(newData.adjustmentTime);
    setLoader(true);
    AXIOS_POST(POST_SERVICE_URL, newData, token)
      .then((res) => {
        setLoader(false);
        
        
        for (let i = 0, len = base64String.length; i < len; i++) {
          // Convert base64 string to Blob
          var byteCharacters = atob(base64String[i].split(",")[1]);
          var byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], { type: "image/png" });

          // Create FormData object and append the Blob
          var formData = new FormData();
          formData.append("file", blob, `filename${i}.png`);
          AXIOS_POST(POST_SERVICE_PIC_URL + `${res.data._id}`, formData, token)
            .then((res) => {})
            .catch((error) => {
              setLoader(false);
              ErrorToast(error);
            });
        }
        setnewData({
          name: "",
          duration: 0,
          description: "",
          preparationTime: 0,
          adjustmentTime: 0,
          petSpecies: [],
          hideDurationOnBooking: true,
          providers: [],
        });
        setPetSpecies([]);
        setProviders([]);
        SuccessToast("Service ajouté avec succès");
        getservices();
        setTimeout(() => {
          getsingleservices(res.data._id)
          setView2(false);
          setView3(false);
        }, 2000);
      })
      .catch((error) => {
        setLoader(false);
        ErrorToast(error);
      });
    }
  };

  // Edit service
  const editservice = (id) => {
     if (editData.name === "") {
      ErrorToast("Veuillez entrer le nom du service");
    }
    else if( editData.description === ""){
      ErrorToast("Veuillez entrer la description du service");

    } else {
      editData.petSpecies = petSpecies;
      editData.providers = providers;
      editData.duration = parseInt(editData.duration);
      editData.preparationTime = parseInt(editData.preparationTime);
      editData.adjustmentTime = parseInt(editData.adjustmentTime);
      setLoader(true);
      AXIOS_PUT(UPDATE_SERVICE_URL + `${id}`, editData, token)
        .then((res) => {
          setLoader(false);
          SuccessToast("Service modifié avec succès");
          getservices();
        })
        .catch((error) => {
          setLoader(false);
          ErrorToast(error);
        });
      }
  };

  const duplicateservice = (id) => {
    AXIOS_POST(POST_SERVICE_DUPLICATE_URL + `${id}`, null, token)
      .then((res) => {
        SuccessToast("Service dupliqué avec succès");
        getservices();
        setTimeout(() => {
          getsingleservices(res.data._id)
          setView2(false);
          setView3(false);
        }, 300);
      })
      .catch((error) => {
        ErrorToast(error);
      });
  };

  useEffect(() => {
    getservices();
  }, []);

  // 3.  watch for changes in the new form

  useEffect(() => {
    if (newData.name !== "" && newData.description !== "") {
      setDatasaved(false);
    } else {
      setDatasaved(true);
    }
  }, [newData]);
  

  // 4. blocker

  let shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) =>
    {
      return !datasaved && currentLocation.pathname !== nextLocation.pathname
    },
    [datasaved]
  ); 
  let blocker = useBlocker(shouldBlock);

  // 5. Reset the blocker if the user cleans the form
  useEffect(() => {
    if (blocker.state === "blocked" && datasaved) {
      blocker.reset();
    }
  }, [blocker, datasaved]);

  const saveServiceInfo = () =>{
     addservice();
     blocker.proceed();
  }


  return (
    <div className="content-container padding-special">
      <ToastContainer />
      {blocker.state === "blocked"   &&
        <Modal open="true">
          <Box sx={style}>
              <div>
               Souhaitez-vous sauvegarder vos modifications avant de quitter cette page ?
              </div>
              <div className="info-actio-btn">
              <button  onClick={() => blocker.reset()}>Non</button>
              <button onClick={saveServiceInfo} >Oui</button>
              </div>
            </Box>
        
        </Modal>
      }
      <h1 className="welcome-title ">
        Bienvenue <br />
        <span className="text_purple">Services</span> en un clic
      </h1>
      <br />

      <div className="services-wrapper">
        <div className="service_list">
          <h1 className="page-name sm-page-name top-25 text_purple">
            Les services de votre établissement
          </h1>
          <div className="list">
            <div
              className="add-list-item text_purple bold border_purple"
              onClick={formToadd}
            >
              <img src={AddIcon} />
              <span>Ajouter un service</span>
            </div>
            {serviceData.length > 0 ?
              serviceData.map((sdata) => (
                <div className="list-item border_purple" style={{ position:"relative" }}>
                  <div
                    className="list-item-name border_purple text_purple bold"
                    onClick={() => getsingleservices(sdata._id)}
                  >
                    {sdata.name}
                  </div>
                  <div className="list-item-action">
                    <button onClick={() => getsingleservices(sdata._id)}>
                      <img src={EditIcon} />
                    </button>
                    <button onClick={() => duplicateservice(sdata._id)}>
                      <img src={CopyIcon} />
                    </button>
                    <button onClick={() => deleteservice(sdata._id)}>
                      <img src={TrashRed} />
                    </button>
                  </div>
                  {/* display blinker if the service is active  */}
                  {Showform && sdata._id === activeId && <div className="blinker" style={{ position:"absolute" }}></div>}
                </div>
              ))
            : 
            getservicesLoader &&
            <div className="list-item border_purple" style={{ justifyContent:"center" }}>
                  <div
                    className="list-item-name border_purple text_purple bold" style={{ justifyContent:"center", alignItems:"center", display:"flex" }}
                  >
                    <Loader color="#8b7bcb"></Loader>
                  </div>
                </div>
              
              }
          </div>
        </div>

        {Showform
          ? oneservice.length != 0 && (
              <div className="service_form_container border_purple">
                {view1 ? <div onClick={()=>setView1(false)} className="sub-nondetail" style={{ borderBottom:"1px solid" }} >
                     Détails du service <ExpandMoreIcon />
                </div>:<div className="sub-container">
                <div className="create-service bg_purple_light border_purple border_none">
                  <div className="title_service">
                    <h3 className="text_purple">Détails du service</h3>
                    <span className="main-color text_purple">
                      {oneservice.name}
                    </span>
                  </div>
                  <div className="title_action">
                    <button
                      onClick={() => setShowform(false)}
                      className="strong main-color text_purple capitalize"
                    >
                      Annuler
                    </button>
                    {loader ? (
                      <button className="active_btn bg_purple_strong">
                        <Loader color="white" />
                      </button>
                    ) : (
                      <button
                        className="active_btn bg_purple_strong"
                        onClick={() => editservice(oneservice._id)}
                      >
                        Enregistrer
                      </button>
                    )}
                    <img src={Arrow} alt="" onClick={()=>setView1(true)} />
                  </div>
                </div>
                <div className="service-form">
                  <input
                    className="border_purple"
                    type="text"
                    required
                    placeholder="Nom du service (ex : radiologie)*"
                    value={editData.name}
                    onChange={(e) =>
                      seteditData({ ...editData, name: e.target.value })
                    }
                  />
                  <textarea
                    placeholder="Description du service*"
                    value={editData.description}
                    onChange={(e) =>
                      seteditData({ ...editData, description: e.target.value })
                    }
                    className="border_purple"
                  ></textarea>
                  <div className="image_container">
                    {editData?.picture?.length === 1 && (
                      <div className="img-box">
                        <img src={Base_Url + `/${editData.picture[0]}`} alt="" />
                      </div>
                    )}
                    {editData?.picture?.length > 1 &&
                      editData.picture.map(
                        (item, index) =>
                          index > 0 && (
                            <div className="img-box" key={index}>
                              <img src={Base_Url + `/${item}`} alt="" />
                            </div>
                          )
                      )}
                    
                    <div>
                    <label htmlFor="img_upload">
                      <img src={Add} alt="add" />
                      <input
                        className="border_purple"
                        id="img_upload"
                        type="file"
                        hidden
                        onChange={(e) => editPicture(editData._id, e)}
                      />
                    </label>
                      {/* create a label for image upload with htmfor same as the one above and a message of Modify image in french  */}
                     <label htmlFor="img_upload" style={{ fontSize:"18px",textAlign:"center",display:"block", fontWeight:"600",cursor:"pointer",border:"none",background:"white", height:"auto" }} className="text_purple">Ajouter 
                      une photo</label>
                    </div>
                   
                  </div>
                  <div className="label-check">
                    <span className="text-data">
                      Afficher le service sur la réservation ?
                    </span>
                    <div className="input-container checkbox-global">
                      <div className="checkbox_box">
                        <input
                          className="border_purple"
                          id="checkBox"
                          type="checkbox"
                          defaultChecked={editData.hideDurationOnBooking}
                          onChange={(e) =>
                            seteditData({
                              ...editData,
                              hideDurationOnBooking: e.target.checked,
                            })
                          }
                          hidden
                        />
                        <label htmlFor="checkBox">
                          <div className="disk"></div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                </div>}
                {view2 ? <div onClick={()=>setView2(false)} className="sub-nondetail" style={{ borderTop:"none" }}>
                    Horaires du service <ExpandMoreIcon />
                </div>:<div className="sub-container"><div className="create-service bg_purple_light border_purple border_none">
                  <div className="title_service">
                    <h3 className="text_purple">Horaires du service </h3>
                  </div>
                  <div className="title_action">
                    <button className="strong main-color capitalize text_purple annule-special">
                      Annuler
                    </button>
                    {loader ? (
                      <button className="active_btn bg_purple_strong">
                        <Loader color="white" />
                      </button>
                    ) : (
                      <button
                        className="active_btn bg_purple_strong"
                        onClick={() => editservice(oneservice._id)}
                      >
                        Enregistrer
                      </button>
                    )}
                    <img src={Arrow} alt="" onClick={()=>setView2(true)} />
                  </div>
                </div>
                <div className="service-form">
                  <div className="label-check">
                    <span className="">Durée du service</span>
                    <select
                      className="mini-input border_purple"
                      value={editData.duration}
                      onChange={(e) =>
                        seteditData({ ...editData, duration: e.target.value })
                      }
                    >
                      <option value={0} selected disabled>
                        --
                      </option>
                      <option value={10}>10 min</option>
                      <option value={15}>15 min</option>
                      <option value={20}>20 min</option>
                      <option value={30}>30 min</option>
                      <option value={40}>40 min</option>
                      <option value={60}>60 min</option>
                      <option value={90}>90 min</option>
                    </select>
                  </div>
                  <div className="label-check">
                    <span className="text-data max-width-520">
                      Afficher le service sur la réservation ?
                    </span>
                    <div className="input-container checkbox-global">
                      <div className="checkbox_box">
                        <input
                          className="border_purple"
                          id="checkBoxresv"
                          type="checkbox"
                          defaultChecked={editData.hideDurationOnBooking}
                          onChange={(e) =>
                            seteditData({
                              ...editData,
                              hideDurationOnBooking: e.target.checked,
                            })
                          }
                          hidden
                        />
                        <label htmlFor="checkBoxresv">
                          <div className="disk"></div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="label-check">
                    <span className="">Temps de préparation du service</span>
                    <select
                      className="mini-input border_purple"
                      value={editData.preparationTime}
                      onChange={(e) =>
                        seteditData({
                          ...editData,
                          preparationTime: e.target.value,
                        })
                      }
                    >
                      <option value={0} selected disabled>
                        --
                      </option>
                      <option value={5}>5 min</option>
                      <option value={10}>10 min</option>
                      <option value={15}>15 min</option>
                      <option value={20}>20 min</option>
                      <option value={25}>25 min</option>
                      <option value={30}>30 min</option>
                    </select>
                  </div>
                  <div className="label-check">
                    <span className="text-data max-width-520">
                      Avez-vous besoin de définir un temps min/max autorisé
                      différent pour le service
                    </span>
                    <div className="input-container checkbox-global">
                      <div className="checkbox_box">
                        <input
                          className="border_purple"
                          id="checkBoxminmaxresv"
                          type="checkbox"
                          defaultChecked={editData.hideDurationOnBooking}
                          onChange={(e) =>
                            seteditData({
                              ...editData,
                              hideDurationOnBooking: e.target.checked,
                            })
                          }
                          hidden
                        />
                        <label htmlFor="checkBoxminmaxresv">
                          <div className="disk"></div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="label-check">
                    <span className="">
                      Délai minimum autorisé avant la réservation du service
                    </span>
                    <select
                      className="mini-input border_purple"
                      value={editData.adjustmentTime}
                      onChange={(e) =>
                        seteditData({
                          ...editData,
                          adjustmentTime: e.target.value,
                        })
                      }
                    >
                       <option value={0} selected disabled>
                        --
                      </option>
                      <option value={5}>5 min</option>
                      <option value={10}>10 min</option>
                      <option value={15}>15 min</option>
                      <option value={20}>20 min</option>
                      <option value={25}>25 min</option>
                      <option value={30}>30 min</option>
                    </select>
                  </div>
                  <div className="label-check">
                    <span className="">
                      Délai maximum autorisé avant la réservation du service
                    </span>
                    <select className="mini-input border_purple">
                      <option value={0} selected disabled>
                        --
                      </option>
                      <option value={30}>30 min</option>
                      <option value={60}>60 min</option>
                    </select>
                  </div>
                  <div className="label-check">
                    <span className="text-data max-width-520">
                      Cacher la durée lors de la réservation ?
                    </span>
                    <div className="input-container checkbox-global">
                      <div className="checkbox_box">
                        <input
                          className="border_purple"
                          id="checkBoxlorsresv"
                          type="checkbox"
                          defaultChecked={editData.hideDurationOnBooking}
                          onChange={(e) =>
                            seteditData({
                              ...editData,
                              hideDurationOnBooking: e.target.checked,
                            })
                          }
                          hidden
                        />
                        <label htmlFor="checkBoxlorsresv">
                          <div className="disk"></div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="label-check">
                    <span className="text-data">
                      Espèce(s) d’animal autorisée(s) pour ce service
                    </span>
                    <div className="checkbox_group">
                      <label className="checkbox_wrapper" htmlFor="checkbox1">
                        <div className="pet_checkbox"></div>
                        <span>Chat</span>
                        <input
                          type="checkbox"
                          className="pet_hidden_check"
                          id="checkbox1"
                          name="cat"
                          value="Chat"
                          onChange={handleCheckboxChangeForPets}
                          hidden
                        />
                        {petSpecies.includes("Chat") && (
                          <img src={tick} alt="" />
                        )}
                      </label>
                      <label className="checkbox_wrapper" htmlFor="checkbox3">
                        <div className="pet_checkbox"></div>
                        <span>Chien</span>
                        <input
                          type="checkbox"
                          className="pet_hidden_check"
                          id="checkbox3"
                          name="dog"
                          value="Chien"
                          onChange={handleCheckboxChangeForPets}
                          hidden
                        />
                        {petSpecies.includes("Chien") && (
                          <img src={tick} alt="" />
                        )}
                      </label>
                      <label className="checkbox_wrapper" htmlFor="checkbox4">
                        <div className="pet_checkbox"></div>
                        <span>NAC</span>
                        <input
                          type="checkbox"
                          className="pet_hidden_check"
                          id="checkbox4"
                          name="nac"
                          value="NAC"
                          onChange={handleCheckboxChangeForPets}
                          hidden
                        />
                        {petSpecies.includes("NAC") && (
                          <img src={tick} alt="" />
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="label-check">
                    <span className="text-data max-width-520">
                      Assigner des horaires de disponibilité spécifiques au
                      service ? (les horaires par défaut du service sont ceux de
                      l’établissement)
                    </span>
                    <div className="input-container checkbox-global">
                      <div className="checkbox_box">
                        <input
                          className="border_purple"
                          id="checkBoxlorAssiresv"
                          type="checkbox"
                          defaultChecked={editData.hideDurationOnBooking}
                          onChange={(e) =>
                            seteditData({
                              ...editData,
                              hideDurationOnBooking: e.target.checked,
                            })
                          }
                          hidden
                        />
                        <label htmlFor="checkBoxlorAssiresv">
                          <div className="disk"></div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="time_table_container">
                  <div className="time_table">
                    <div className="timetable_action">
                      <div
                        onClick={() =>
                          setTab({ calendar: true, settings: false })
                        }
                        className={tab.calendar && "active"}
                      >
                        Horaires de la semaine
                      </div>
                      <div
                        onClick={() =>
                          setTab({ calendar: false, settings: true })
                        }
                        className={tab.settings && "active"}
                      >
                        Horaires spéciaux
                      </div>
                    </div>
                    {tab.settings ? (
                      <TimeTableSettings entId={oneservice._id} />
                    ) : (
                      <GridTimeTable serviceId={oneservice._id} />
                    )}
                  </div>
                </div>
                </div>}
                {view3 ? <div onClick={()=>setView3(false)} className="sub-nondetail">
                    Vétérinaires attachés à ce service <ExpandMoreIcon />
                </div>:<div className="sub-container"><div className="create-service bg_purple_light border_purple border_none">
                  <div className="title_service">
                    <h3 className="text_purple">
                      Vétérinaires attachés à ce service
                    </h3>
                  </div>
                  <div className="title_action">
                    <button
                      onClick={hideforms}
                      className="strong main-color capitalize text_purple annule-special"
                    >
                      Annuler
                    </button>
                    {loader ? (
                      <button className="active_btn bg_purple_strong">
                        <Loader color="white" />
                      </button>
                    ) : (
                      <button
                        className="active_btn bg_purple_strong"
                        onClick={() => editservice(oneservice._id)}
                      >
                        Enregistrer
                      </button>
                    )}
                    <img src={Arrow} alt="" onClick={()=>setView3(true)} />
                  </div>
                </div>
                <div className="service-form">
                  {/* // search bar for vet list  */}
                  {vetlist.length > 0 && 
                  <div className="search-bar border-purple">
                    <div className="container">
                      <input
                        type="text"
                        placeholder="Rechercher un vétérinaire"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <img src={Searchpurple} alt="add" />
                    </div>
                  </div>
                  }

                  {vetlist.length > 0 ? (
                    vetlist.map((vet, index) => {
                      if (searchTerm == "") {
                        return (
                          <div className="vet-list" key={index}>
                            <div className="vet_attach_info">
                              <div className="vet_img">
                                <img
                                  src={
                                    vet.picture.includes("http")
                                      ? vet.picture
                                      : Base_Url + `/` + vet.picture
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="h4">
                                {vet.firstname} {vet.lastname}
                              </div>
                            </div>
                            <div className="vet_checker_info">
                              <div className="checkbox_group">
                                <label
                                  className="checkbox_wrapper"
                                  htmlFor={`checkbox${5 + index}`}
                                >
                                  <div className="pet_checkbox"></div>
                                  <span></span>
                                  <input
                                    type="checkbox"
                                    className="pet_hidden_check"
                                    id={`checkbox${5 + index}`}
                                    value={vet._id}
                                    onChange={handleCheckboxChangeForProviders}
                                    hidden
                                  />
                                  {providers.includes(`${vet._id}`) && (
                                    <img src={tick} alt="" />
                                  )}
                                </label>
                              </div>
                            </div>
                          </div>
                        );
                      } else if (
                        vet?.firstname
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        vet?.lastname
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return (
                          <div className="vet-list" key={index}>
                            <div className="vet_attach_info">
                              <div className="vet_img">
                                <img
                                  src={vet.picture ? Base_Url + `/${vet.picture}` : "https://media.istockphoto.com/id/1303362255/photo/young-happy-veterinary-nurse-smiling-while-playing-with-a-dog-high-quality-photo.jpg?s=612x612&w=0&k=20&c=_kIKIzB4GF5WDLCf2yK1XMQk9JtxoL9XPK_nJmTZ5JQ="}
                                  alt=""
                                />
                              </div>
                              <div className="h4">
                                {vet.firstname} {vet.lastname}
                              </div>
                            </div>
                            <div className="vet_checker_info">
                              <div className="checkbox_group">
                                <label
                                  className="checkbox_wrapper"
                                  htmlFor={`checkbox${5 + index}`}
                                >
                                  <div className="pet_checkbox"></div>
                                  <span></span>
                                  <input
                                    type="checkbox"
                                    className="pet_hidden_check"
                                    id={`checkbox${5 + index}`}
                                    value={vet._id}
                                    onChange={handleCheckboxChangeForProviders}
                                    hidden
                                  />
                                  {providers.includes(`${vet._id}`) && (
                                    <img src={tick} alt="" />
                                  )}
                                </label>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })
                  ) : ''
                  }
                  

                </div>
                <div className="assign-attrb service-form">
                        <h3>Ajouter un vétérinaire pour l'attribuer au service.</h3>
                        <button onClick={()=>navigate('/clinic/dashboard/veterinary/action')}>Ajouter un vétérinaire</button>
                  </div>
                
                </div>}
                
                
              </div>
            )
          : ''}

        {addform ? (
          <div className="service_form_container border border-radius border_purple">
            <div className="create-service bg_purple_light border_purple border_none">
              <div className="title_service">
                <h3 className="text_purple">Ajouter un service</h3>
                <span>{newData.name}</span>
              </div>
              <div className="title_action">
                <button
                  onClick={() => setaddform(false)}
                  className="strong main-color capitalize text_purple annule-special"
                >
                  Annuler
                </button>

                {loader ? (
                  <button className="active_btn bg_purple_strong">
                    <Loader color="white" />
                  </button>
                ) : (
                  <button
                    className="active_btn bg_purple_strong"
                    onClick={addservice}
                  >
                    Enregistrer
                  </button>
                )}
              </div>
            </div>
            <div className="service-form">
              <input
                className="border_purple"
                type="text"
                placeholder="Nom du service (ex : radiologie)*"
                value={newData.name}
                onChange={(e) =>
                  setnewData({ ...newData, name: e.target.value })
                }
              />
              <textarea
                placeholder="Description du service*"
                value={newData.description}
                className="border_purple"
                onChange={(e) =>
                  setnewData({ ...newData, description: e.target.value })
                }
              ></textarea>
              <div className="image_container border_purple">
                {base64String.length > 0 &&
                  base64String.map((item, index) => (
                    <div className="img-box border_purple" key={index}>
                      <img src={item} alt="" />
                    </div>
                  ))}
                <div>
                <label htmlFor="img_upload">
                  <img src={Add} alt="add" />
                  <input
                    className="border_purple"
                    id="img_upload"
                    type="file"
                    hidden
                    onChange={addPicture}
                  />
                </label>
                <label htmlFor="img_upload" style={{ fontSize:"18px",textAlign:"center",display:"block", fontWeight:"700",cursor:"pointer",border:"none",background:"white", height:"auto" }} className="text_purple">Ajouter 
une photo</label>
                </div>
              </div>
              <div className="label-check">
                <span className="text-data">
                  Afficher le service sur la réservation ?
                </span>
                <div className="input-container checkbox-global">
                  <div className="checkbox_box">
                    <input
                      className="border_purple"
                      id="checkBox"
                      type="checkbox"
                      defaultChecked={newData.hideDurationOnBooking}
                      onChange={(e) =>
                        setnewData({
                          ...newData,
                          hideDurationOnBooking: e.target.checked,
                        })
                      }
                      hidden
                    />
                    <label htmlFor="checkBox">
                      <div className="disk"></div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Services;
