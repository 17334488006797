import React, { useCallback, useEffect, useState } from "react";
import Map from "../common/Map";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import Add from "../../../assets/Icons/add_pupple.png";
import { AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from "../../../config/axios";
import {
  GET_SINGLE_CLINIC_URL,
  POST_CLINICS_PIC_URL,
  POST_SERVICE_PIC_URL,
  UPDATE_CLINIC_INFO_URL,
  googleMapsApiKey,
} from "../../../helper/Url";
import { useBlocker, unstable_usePrompt, useNavigate } from "react-router-dom";
import Loader from "../../../helper/Loader/Loader";
import PlaceAutocomplete from "../common/Autocomplete";
import ErrorToast from "../../../helper/Message/ErrorToast";
import { ReactComponent as AddRed } from "../../../assets/Icons/AddRed.svg";
import { ToastContainer } from "react-toastify";
import SuccessToast from "../../../helper/Message/SuccessToast";
import { Base_Url } from "../../../config/variables";
import { Form, useForm } from "react-hook-form";
import FormErrors from "../../../helper/Message/FormErrors";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { selectUserInfo, setUserInfo } from "../../../features/UserInfoSlice";
import ReactRouterPrompt from "react-router-prompt";
import { Box, Modal } from "@mui/material";
import PhoneField from "../../common/PhoneField";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 5,
  borderColor: "primary.main",
  boxShadow: 24,
  color: "black",
  p: 4,
};

const MyclinicInfo = () => {
  console.log("MyclinicInfo");
  const token = localStorage.getItem("user_token");
  const id = localStorage.getItem("user_id");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ latitude: 0, longitude: 0 });
  const [ischeckbox, setischeckbox] = useState(false);
  const [lat, setlat] = useState(47.46171229999999);
  const [lng, setlng] = useState(8.5508703);
  const [place, setplace] = useState("");
  const [base64String, setbase64String] = useState("");
  const dispatch = useDispatch();
  const [formUnsaved, setFormUnsaved] = useState(false);
  const [datasaved, setdatasaved] = useState(true);
  const [userData, setUserData] = useState([]);
  const [telephone, setTelephone] = useState("");
  const [telephone1, setTelephone1] = useState("");
  // const [telephone2, setTelephone2] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    watch: watch1,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    watch,
    formState: { errors: errors2 },
  } = useForm();

  // watch adress, pays, city, postalCode

  const handleInputChange = (e) => {
    setdatasaved(false);
    const { name, value } = e.target;
    console.log("handleInputChange", name, value);
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    setFormUnsaved(true);

    // Update the form field using setValue2
    if (name === 'city' || name === 'postalCode') {
      setValue2(name, value);
    }
  };

  const handlecheckboxChange = (e) => {
    setdatasaved(false);
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };
  const updateLocation = (lat, lng, value, addressComponents) => {
    setlat(lat);
    setlng(lng);
    setplace(value);
    
    // Update form data with address components
    if (addressComponents) {
      const city = addressComponents.find(component => 
        component.types.includes('locality'))?.long_name || '';
      const postalCode = addressComponents.find(component => 
        component.types.includes('postal_code'))?.long_name || '';
      
      // Use full formatted address
      setValue2('address', value);
      setValue2('city', city);
      setValue2('postalCode', postalCode);
      setValue2('country', 'Suisse');
      
      // Update formData state
      setFormData(prev => ({
        ...prev,
        address: value,
        city,
        postalCode,
        latitude: lat,
        longitude: lng,
        otherInfo: value
      }));
      console.log(formData);
    }
  };

  const onhandleSubmit2 = (data) => {
    setdatasaved(true);
    setLoading(true);
    data.telephone1 = telephone1;
    
    const payload = {
      ...data,
      geolocation: { coordinates: [lng, lat] },
      latitude: lat,
      longitude: lng,
      otherInfo: place,
      // Use full formatted address
      address: place || data.address,
      city: formData.city || data.city,
      postalCode: formData.postalCode || data.postalCode,
      country: formData.country || data.country || 'Suisse'
    };

    AXIOS_PATCH(
      UPDATE_CLINIC_INFO_URL + `${id}`,
      payload,
      token
    )
      .then((res) => {
        setLoading(false);
        localStorage.setItem("user_facilityName", res.data.facilityName);
        dispatch(setUserInfo(res.data));
        SuccessToast("Informations mises à jour avec succès");
        getClicInfo();
      })
      .catch((error) => {
        ErrorToast(error);
        setLoading(false);
      });
  };

  const onhandleSubmit1 = (data) => {
    setdatasaved(true);
    setLoading(true);
    data.telephone = telephone;
    AXIOS_PATCH(UPDATE_CLINIC_INFO_URL + `${id}`, data, token)
      .then((res) => {
        localStorage.setItem("user_firstname", res.data.firstname);
        localStorage.setItem("user_lastname", res.data.lastname);
        dispatch(setUserInfo(res.data));
        setLoading(false);
        SuccessToast("Informations mises à jour avec succès");
        getClicInfo();
      })
      .catch((error) => {
        ErrorToast(error);
        setLoading(false);
      });
  };

  const getClicInfo = () => {
    AXIOS_GET(GET_SINGLE_CLINIC_URL + `${id}`, token)
      .then((res) => {
        setUserData(res.data);
        setlng(res.data.geolocation?.coordinates[0]);
        setlat(res.data.geolocation?.coordinates[1]);
        setplace(res.data.otherInfo);
        setFormData({ ...formData, ...res.data });
        setischeckbox(formData?.publicTransport);
        // set value for only field in form one of Informations de la personne de contact
        if (res.data) {
          setValue(
            "firstname",
            res.data.firstname === " " ? "" : res.data.firstname
          );
          setValue(
            "lastname",
            res.data.lastname === " " ? "" : res.data.lastname
          );
          setValue("civility", res.data.civility);
          setValue("title", res.data.title);
          setValue("email", res.data.email);
          setValue("telephone", res.data.telephone);
          setTelephone(res.data.telephone);
        }
        // set value for only field in form two of Informations sur l’établissement
        if (res.data) {
          setValue2("facilityName", res.data.facilityName);
          setValue2("address", res.data.address);
          setValue2("postalCode", res.data.postalCode);
          setValue2("city", res.data.city);
          setValue2("country", "Suisse");
          setValue2("publicTransport", res.data.publicTransport);
          setValue2("parking", res.data.parking);
          setValue2("otherInfo", res.data.otherInfo);
          setValue2("cachemail", res.data.cachemail);
          setValue2("telephone1", res.data.telephone1);
          // setValue2("telephone2", res.data.telephone2);
          setValue2("webLink", res.data.webLink);
          setValue2("facebookLink", res.data.facebookLink);
          setValue2("instagramLink", res.data.instagramLink);
          setValue2("linkedinLink", res.data.linkedinLink);
          setValue2("twitterLink", res.data.twitterLink);
          setValue2("otherDescription", res.data.otherDescription);
          setTelephone1(res.data.telephone1);
          // setTelephone2(res.data.telephone2);
        }
      })
      .catch((error) => {
        console.log("The response error is :", error);
      });
  };

  const editPicture = (id, e) => {
    const file = e.target.files[0];
    const formdata = new FormData();
    formdata.append("file", file);
    let reader = new FileReader(); // Create a new FileReader object
    reader.onloadend = function () {
      // 'result' contains the base64 data of the uploaded file
      setbase64String(reader.result);
    };
    // Read the file as data URL (base64 format)
    reader.readAsDataURL(file);
    AXIOS_POST(POST_CLINICS_PIC_URL + `${id}`, formdata, token)
      .then((res) => {
        getClicInfo();
        SuccessToast("Photo modifiée avec succès");
      })
      .catch((error) => {
        ErrorToast(error);
      });
  };

  useEffect(() => {
    getClicInfo();
  }, []);

  // get geolocation when adress, pays, city, postalCode change and other informations of the form two

  const watchedAddress = watch("address");
  const watchedPostalCode = watch("postalCode");
  const watchedCity = watch("city");
  const watchedCountry = watch("country");
  const watchedPublicTransport = watch("publicTransport");
  const watchedParking = watch("parking");
  const watchedOtherInfo = watch("otherInfo");
  const watchedCachemail = watch("cachemail");
  const watchedTelephone1 = watch("telephone1");
  // const watchedTelephone2 = watch("telephone2");
  const watchedFacilityName = watch("facilityName");
  const watchedEmail = watch("email");
  const watchedOtherDescription = watch("otherDescription");
  const watchedFacebookLink = watch("facebookLink");
  const watchedInstagramLink = watch("instagramLink");
  const watchedLinkedInLink = watch("linkedinLink");
  const watchedTwitterLink = watch("twitterLink");

  // watch data in form one of Informations de la personne de contact

  const watchedFirstname = watch1("firstname");
  const watchedLastname = watch1("lastname");
  const watchedCivility = watch1("civility");
  const watchedTitle = watch1("title");
  const watchedTelephone = watch1("telephone");

  const fetchGoogleMapsData = async () => {
    try {
      if (!watchedAddress) return;

      const addressString = `${watchedAddress},${watchedCity || ''},${watchedPostalCode || ''},${watchedCountry || 'Suisse'}`;
      const encodedAddress = encodeURIComponent(addressString);

      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${googleMapsApiKey}&region=CH`
      );

      if (response.data.results.length > 0) {
        const result = response.data.results[0];
        const { lat, lng } = result.geometry.location;
        
        // Update coordinates
        setlat(lat);
        setlng(lng);
        
        // Update place with formatted address
        setplace(result.formatted_address);
        
        // Update formData with new coordinates
        setFormData(prev => ({
          ...prev,
          latitude: lat,
          longitude: lng,
          otherInfo: result.formatted_address
        }));
      } else {
        console.warn("No results found for the provided address");
      }
    } catch (error) {
      console.error("Error fetching Google Maps Geocoding data:", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (watchedAddress && !place) {
        fetchGoogleMapsData();
      }
    }, 500); // Add debounce to prevent too many API calls

    return () => clearTimeout(timer);
  }, [watchedAddress, watchedCity, watchedPostalCode, watchedCountry, place]);

  let shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) => {
      return !datasaved && currentLocation.pathname !== nextLocation.pathname;
    },
    [datasaved]
  );
  let blocker = useBlocker(shouldBlock);

  // setdatasaved to false when watched fields change

  useEffect(() => {
    if (
      watchedAddress != userData.address ||
      watchedFirstname != userData.firstname ||
      watchedLastname != userData.lastname ||
      watchedTitle != userData.title ||
      watchedCivility != userData.civility ||
      watchedTelephone != userData.telephone ||
      watchedCountry != userData.country ||
      watchedCity != userData.city ||
      watchedPostalCode != userData.postalCode ||
      watchedPublicTransport != userData.publicTransport ||
      watchedParking != userData.parking ||
      watchedOtherInfo != userData.otherInfo ||
      watchedCachemail != userData.cachemail ||
      watchedTelephone1 != userData.telephone1 
      // watchedTelephone2 != userData.telephone2
    ) {
      setdatasaved(false);
    } else {
      setdatasaved(true);
    }
  }, [
    watchedAddress,
    userData,
    watchedFirstname,
    watchedLastname,
    watchedTitle,
    watchedCivility,
    watchedTelephone,
    watchedCountry,
    watchedCity,
    watchedPostalCode,
    watchedAddress,
    watchedPublicTransport,
    watchedParking,
    watchedOtherInfo,
    watchedCachemail,
    watchedTelephone1,
    // watchedTelephone2,
  ]);

  // Reset the blocker if the user cleans the form
  useEffect(() => {
    if (blocker.state === "blocked" && datasaved) {
      blocker.reset();
    }
  }, [blocker, datasaved]);

  const SaveUserInfo = () => {
    onhandleSubmit1({
      firstname: watchedFirstname,
      lastname: watchedLastname,
      civility: watchedCivility,
      title: watchedTitle,
      telephone: watchedTelephone,
    });
    onhandleSubmit2({
      facilityName: watchedFacilityName,
      address: watchedAddress,
      postalCode: watchedPostalCode,
      city: watchedCity,
      country: watchedCountry,
      publicTransport: watchedPublicTransport,
      parking: watchedParking,
      otherInfo: watchedOtherInfo,
      cachemail: watchedCachemail,
      telephone1: watchedTelephone1,
      // telephone2: watchedTelephone2,
      facebookLink: watchedFacebookLink,
      instagramLink: watchedInstagramLink,
      linkedinLink: watchedLinkedInLink,
      twitterLink: watchedTwitterLink,
      otherDescription: watchedOtherDescription,
    });
    setFormUnsaved(false);
    setdatasaved(true);
    blocker.proceed();
  };

  // Helper function to validate URLs
  const isValidURL = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  // Add console logs to debug coordinate values
  useEffect(() => {
    console.log("Map coordinates:", { lat, lng });
  }, [lat, lng]);

  return (
    <div className="content-container">
      <ToastContainer />
      {blocker.state === "blocked" && (
        <Modal open="true">
          <Box sx={style}>
            <div>
              Souhaitez-vous sauvegarder vos modifications avant de quitter
              cette page ?
            </div>
            <div className="info-actio-btn">
              <button onClick={() => blocker.reset()}>Non</button>
              <button onClick={SaveUserInfo}>Oui</button>
            </div>
          </Box>
        </Modal>
      )}

      <h1 className="welcome-title">
        Bienvenue <br />
        <span className="text_purple">Mes informations </span>en un clic
      </h1>
      <div className="change-form">
        <form className="" onSubmit={handleSubmit(onhandleSubmit1)}>
          <h1 className="form-subtitle text_purple">
            Informations de la personne de contact
          </h1>
          <div
            className="flex-container gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <label className="text_purple" htmlFor="">
                Prénom*
              </label>
              <input
                type="text"
                name="firstname"
                onChange={handleInputChange}
                placeholder="Prénom*"
                {...register("firstname", { required: true })}
                className="border_purple margin-bottom-13"
              />
              {errors.firstname && (
                <FormErrors>Veuillez entrer votre prénom</FormErrors>
              )}
            </div>
            <div className="input-container">
              <label className="text_purple" htmlFor="">
                Nom*
              </label>
              <input
                type="text"
                name="lastname"
                onChange={handleInputChange}
                placeholder="Nom*"
                {...register("lastname", { required: true })}
                className="border_purple margin-bottom-13"
              />
              {errors.lastname && (
                <FormErrors>Veuillez entrer votre nom</FormErrors>
              )}
            </div>
          </div>
          <div
            className="flex-container gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <label className="text_purple" htmlFor="">
                Civilité*
              </label>
              <select
                id=""
                name="civility"
                onChange={handleInputChange}
                {...register("civility", { required: true })}
                className="border_purple margin-bottom-13"
              >
                <option value="" selected disabled>
                  Civilité*
                </option>
                <option value="Mr">Monsieur</option>
                <option value="Mrs">Madame</option>
                <option value="Ms">Mademoiselle</option>
              </select>
              {errors.civility && (
                <FormErrors>Veuillez entrer votre civilité</FormErrors>
              )}
            </div>
            <div className="input-container">
              <label className="text_purple" htmlFor="">
                Titre*
              </label>
              <select
                id=""
                name="title"
                onChange={handleInputChange}
                {...register("title", { required: true })}
                className="border_purple margin-bottom-13"
              >
                <option value="" selected disabled>
                  Titre*
                </option>
                <option value="Dr">Dr.</option>
                <option value="Mme">Mme.</option>
                <option value="Mlle">Mlle.</option>
                <option value="M">M.</option>
                <option value="Me">Me.</option>
                <option value="Pr">Pr.</option>
              </select>
              {errors.title && (
                <FormErrors>Veuillez entrer votre titre</FormErrors>
              )}
            </div>
          </div>
          <div
            className="flex-container gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <label className="text_purple" htmlFor="">
                E-mail*
              </label>
              <input
                type="email"
                name="email"
                readOnly
                placeholder="E-mail*"
                {...register("email", { required: true })}
                className="border_purple margin-bottom-13"
              />
              {errors.email && (
                <FormErrors>Veuillez entrer votre email</FormErrors>
              )}
            </div>
            <div className="input-container">
              <label className="text_purple" htmlFor="">
                Téléphone*
              </label>
              <PhoneField
                containerStyle={{
                  width: "100%",
                  height: "52px",
                  marginBottom: "20px",
                }}
                buttonStyle={{ borderColor: "#8b7bcb" }}
                inputStyle={{
                  width: "100%",
                  height: "52px",
                  borderColor: "#8b7bcb",
                }}
                placeholder="Votre téléphone*"
                telephone={telephone}
                settelephone={setTelephone}
                defaultCountryCode="+41"
              />
              {errors.telephone?.type === "required" && (
                <FormErrors>Le téléphone est requis</FormErrors>
              )}
              {errors.telephone?.type === "pattern" && (
                <FormErrors>
                  Le téléphone doit commencer par 41 et avoir 11 chiffres
                  (41xxxxxxxxx)
                </FormErrors>
              )}
            </div>
          </div>
          <div className="input-container btn-container">
            {loading ? (
              <button type="button" className="bg_purple_strong">
                <Loader color="#ffffff"></Loader>
              </button>
            ) : (
              <button className="bg_purple_strong">
                VALIDER LES MODIFICATIONS
              </button>
            )}
          </div>
        </form>
        <form className="" onSubmit={handleSubmit2(onhandleSubmit2)}>
          <h1 className="form-subtitle text_purple">
            Informations sur l’établissement
          </h1>
          <div
            className="flex-container gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <label className="text_purple" htmlFor="">
                Nom de l’établissement*
              </label>
              <input
                typ
                placeholder="Nom de l’établissement*"
                name="facilityName"
                onChange={handleInputChange}
                {...register2("facilityName", { required: true })}
                className="border_purple margin-bottom-13"
              />
              {errors2.facilityName && (
                <FormErrors>
                  Veuillez entrer le nom de l’établissement
                </FormErrors>
              )}
            </div>
            <div className="input-container"></div>
          </div>
          <div
            className="flex-container gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <label className="text_purple" htmlFor="">
                Adresse*
              </label>
              <PlaceAutocomplete
                placeholder="Votre Adresse*"
                className="border_purple margin-bottom-13"
                updateLocation={updateLocation}
                onChange={handleInputChange}
                name="address"
                value={formData.address}
                {...register2("address", { required: true })}
              />
              {errors2.address && (
                <FormErrors>Veuillez entrer votre adresse</FormErrors>
              )}
            </div>
          </div>
          <div
            className="flex-container gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <label className="text_purple" htmlFor="">
                Code postal*
              </label>
              <input
                type="text"
                placeholder="Code postal*"
                name="postalCode"
                value={formData.postalCode || ''}
                onChange={handleInputChange}
                {...register2("postalCode", { required: true })}
                className="border_purple margin-bottom-13"
              />
              {errors2.postalCode && (
                <FormErrors>Veuillez entrer votre code postal</FormErrors>
              )}
            </div>
            <div className="input-container">
              <label className="text_purple" htmlFor="">
                Ville*
              </label>
              <input
                type="text"
                placeholder="Votre Ville*"
                name="city"
                value={formData.city || ''}
                onChange={handleInputChange}
                {...register2("city", { required: true })}
                className="border_purple margin-bottom-13"
              />
              {errors2.city && (
                <FormErrors>Veuillez entrer votre ville</FormErrors>
              )}
            </div>
          </div>
          <div
            className="flex-container gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container input-container-2">
              <label className="text_purple" htmlFor="">
                Pays*
              </label>
              <select
                id=""
                name="country"
                onChange={handleInputChange}
                {...register2("country", { required: true, value: "Suisse" })}
                className="border_purple margin-bottom-13"
              >
                <option value="" selected>
                  Pays*
                </option>
                <option value="Suisse">Suisse</option>
              </select>
              {errors2.country && (
                <FormErrors>Veuillez entrer votre pays</FormErrors>
              )}
            </div>
            <div className="input-container input-container-2"></div>
          </div>
          <div
            className="flex-container gap-10"
            style={{ alignItems: "unset" }}
          >
            <div className="input-container input-container-2">
              <div className="checkbox_title">
                L’établissement est-il accessible en transports publiques ?
              </div>
            </div>
            <div className="input-container checkbox-global">
              <div className="checkbox_box">
                <input
                  id="checkBox"
                  type="checkbox"
                  name="publicTransport"
                  // defaultChecked={formData.publicTransport}
                  // value={formData.publicTransport}
                  onChange={handlecheckboxChange}
                  {...register2("publicTransport")}
                  hidden
                  className="border_purple"
                />
                <label className="text_purple" htmlFor="checkBox">
                  <div className="disk"></div>
                </label>
              </div>
            </div>
          </div>
          <div
            className="flex-container gap-10"
            style={{ alignItems: "unset" }}
          >
            <div className="input-container input-container-2 checkbox-global">
              <div className="checkbox_title">
                L’établissement dispose-t-il d’un parking ?
              </div>
            </div>
            <div className="input-container checkbox-global">
              <div className="checkbox_box">
                <input
                  id="checkBox2"
                  type="checkbox"
                  name="parking"
                  // defaultChecked={formData.parking}
                  // value={formData.parking}
                  onChange={handlecheckboxChange}
                  {...register2("parking")}
                  hidden
                />
                <label className="text_purple" htmlFor="checkBox2">
                  <div className="disk"></div>
                </label>
              </div>
            </div>
          </div>
          <div
            className="flex-container gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container input-container-2">
              <label className="text_purple" htmlFor="">
                Autres informations de localisation
              </label>
              <PlaceAutocomplete
                placeholder="Autres informations de localisation"
                className="border_purple"
                updateLocation={updateLocation}
                onChange={handleInputChange}
                name="otherInfo"
                value={place}
                {...register2("otherInfo")}
              />
            </div>
          </div>
          <div
            className="flex-container   gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container input-container-2">
              <Map 
                height={"219px"} 
                latitude={Number(lat)} // Ensure lat is a number
                longitude={Number(lng)} // Ensure lng is a number
                zoom={15} // Add a zoom level
              />
            </div>
          </div>
          <div
            className="flex-container gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container input-container-2">
              <label className="text_purple" htmlFor="">
                E-mail*
              </label>
              <input
                type="text"
                placeholder="E-mail de contact*"
                className="border_purple"
                name="email"
                readOnly
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-container input-container-2">
              <br />
              <div
                className="checkbox-global"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <div className="checkbox_title">
                  Cacher l’e-mail de l’établissement dans le processus de
                  réservation.
                </div>
                <div className="checkbox_box">
                  <input
                    id="checkBox3"
                    type="checkbox"
                    hidden
                    // defaultChecked={formData.cachemail}
                    name="cachemail"
                    // value={formData.cachemail}
                    onChange={handlecheckboxChange}
                    {...register2("cachemail")}
                  />
                  <label className="text_purple" htmlFor="checkBox3">
                    <div className="disk"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex-container gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <label className="text_purple" htmlFor="">
                Téléphone *
              </label>
              {/* <div className="input-icon border_purple flex-container margin-bottom-13">
                <AddRed />
              <input
                type="number"
                placeholder="Téléphone 1"
                name="telephone1"
                onChange={handleInputChange}
                {...register2("telephone1", {
                  required: "Le téléphone est requis",
                  pattern: {
                    value: /^41\d{9}$/,
                    message:
                      "Le téléphone doit commencer par 41 et avoir 11 chiffres (41xxxxxxxxx)",
                  },
                })}
                className="border_purple margin-bottom-13"
              />
              </div> */}
              <PhoneField
                containerStyle={{
                  width: "100%",
                  height: "52px",
                  marginBottom: "20px",
                }}
                buttonStyle={{ borderColor: "#8b7bcb" }}
                inputStyle={{
                  width: "100%",
                  height: "52px",
                  borderColor: "#8b7bcb",
                  paddingLeft: "36px !important",
                }}
                placeholder="Votre téléphone*"
                telephone={telephone1}
                settelephone={setTelephone1}
                defaultCountryCode="+41"
              />
              {errors2.telephone1?.type === "required" && (
                <FormErrors>Le téléphone est requis</FormErrors>
              )}
              {errors2.telephone1?.type === "pattern" && (
                <FormErrors>
                  Le téléphone doit commencer par 41 et avoir 11 chiffres
                  (41xxxxxxxxx)
                </FormErrors>
              )}
            </div>
            {/* <div className="input-container">
              <label className="text_purple" htmlFor="">
                Téléphone 2
              </label>
              <PhoneField
                containerStyle={{
                  width: "100%",
                  height: "52px",
                  marginBottom: "20px",
                }}
                buttonStyle={{ borderColor: "#8b7bcb" }}
                inputStyle={{
                  width: "100%",
                  height: "52px",
                  borderColor: "#8b7bcb",
                  paddingLeft: "36px !important",
                }}
                placeholder="Votre téléphone*"
                telephone={telephone2}
                settelephone={setTelephone2}
              />
            </div> */}
          </div>

          <div className="flex-container gap-10" style={{ alignItems: "baseline", marginTop: "75px" }}>
            <div className="input-container input-container-2">
              <div className="link_container border_purple">
                <input
                  type="text"
                  placeholder="Lien vers le site web"
                  name="webLink"
                  onChange={handleInputChange}
                  {...register2("webLink", {
                    validate: (value) => {
                      if (value && !isValidURL(value)) {
                        return "Veuillez saisir un lien valide";
                      }
                    },
                  })}
                />
              </div>
            </div>
            <div className="input-container input-container-2"></div>
          </div>
          <div className="flex-container gap-10" style={{ alignItems: "baseline" }}>
            <div className="input-container input-container-2">
              <div className="link_container border_purple margin-bottom-13">
                <FacebookIcon />
                <input
                  type="text"
                  placeholder="Lien vers la page Facebook"
                  name="facebookLink"
                  onChange={handleInputChange}
                  {...register2("facebookLink", {
                    validate: (value) => {
                      if (value && !value.includes("facebook.com")) {
                        return "Veuillez saisir un lien facebook valide";
                      }
                    },
                  })}
                />
              </div>
              {errors2.facebookLink && (
                <FormErrors>Veuillez entrer un lien valide</FormErrors>
              )}
            </div>
            <div className="input-container input-container-2">
              <div className="link_container border_purple margin-bottom-13">
                <InstagramIcon />
                <input
                  type="text"
                  placeholder="Lien vers la page Instagram"
                  name="instagramLink"
                  onChange={handleInputChange}
                  {...register2("instagramLink", {
                    validate: (value) => {
                      if (value && !value.includes("instagram.com")) {
                        return "Veuillez saisir un lien instagram valide";
                      }
                    },
                  })}
                />
              </div>
              {errors2.instagramLink && (
                <FormErrors>Veuillez saisir un lien instagram valide</FormErrors>
              )}
            </div>
          </div>
          <div className="flex-container  gap-10" style={{ alignItems: "baseline" }}>
            <div className="input-container input-container-2">
              <div className="link_container border_purple margin-bottom-13">
                <LinkedInIcon />
                <input
                  type="text"
                  placeholder="Lien vers la page LinkedIn"
                  name="linkedinLink"
                  onChange={handleInputChange}
                  {...register2("linkedinLink", {
                    validate: (value) => {
                      if (value && !value.includes("linkedin.com")) {
                        return "Veuillez saisir un lien linkedin valide";
                      }
                    },
                  })}
                  className="border_purple"
                />
              </div>
              {errors2.linkedinLink && (
                <FormErrors>Veuillez saisir un lien linkedin valide</FormErrors>
              )}
            </div>
            <div className="input-container input-container-2">
              <div className="link_container border_purple margin-bottom-13">
                <TwitterIcon />
                <input
                  type="text"
                  placeholder="Lien vers la page Tweeter"
                  name="twitterLink"
                  onChange={handleInputChange}
                  {...register2("twitterLink", {
                    validate: (value) => {
                      if (value && !value.includes("twitter.com")) {
                        return "Veuillez saisir un lien twitter valide";
                      }
                    },
                  })}
                />
              </div>
              {errors2.twitterLink && (
                <FormErrors>Veuillez saisir un lien twitter valide</FormErrors>
              )}
            </div>
          </div>
          <div
            className="flex-container gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container input-container-2">
              <strong>
                Pour consulter et modifier les horaires de l’établissement
              </strong>
            </div>
            <div className="input-container btn-container">
              <button
                type="button"
                onClick={() => navigate("/clinic/dashboard/timetable")}
                className="bg_purple_strong"
              >
                Cliquez ici
              </button>
            </div>
          </div>
          <div
            className="flex-container gap-10"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <textarea
                placeholder="Présentez votre établissement"
                name="otherDescription"
                onChange={handleInputChange}
                {...register2("otherDescription")}
                className="border_purple"
              ></textarea>
            </div>
          </div>

          <div className="image_container ">
            <div className="img-box">
              <img
                className="border_purple "
                src={
                  formData?.picture?.includes("http")
                    ? formData.picture
                    : `${Base_Url}/${formData.picture}`
                }
                alt=""
              />
            </div>
            <div>
              <label htmlFor="img_upload">
                <img src={Add} alt="add" />
                <input
                  id="img_upload"
                  type="file"
                  hidden
                  onChange={(e) => editPicture(formData._id, e)}
                />
              </label>
              <label
                htmlFor="img_upload"
                style={{
                  fontSize: "13px",
                  textAlign: "center",
                  display: "block",
                  fontWeight: "600",
                  cursor: "pointer",
                  border: "none",
                  background: "white",
                  height: "auto",
                }}
                className="text_purple"
              >
                {" "}
                Ajouter une photo{" "}
              </label>
            </div>
          </div>
          <br />
          <br />
          <div className="input-container btn-container">
            {loading ? (
              <button type="button" className="bg_purple_strong">
                <Loader color="#ffffff"></Loader>
              </button>
            ) : (
              <button className="bg_purple_strong">
                VALIDER LES MODIFICATIONS
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default MyclinicInfo;
