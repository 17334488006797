import React from "react";
import addIcon from "../../../assets/Icons/addIcon.svg";

import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { AXIOS_GET } from "../../../config/axios";
import { GET_PETS_URL } from "../../../helper/Url";
import { useState } from "react";
import Translate from "../../../config/Translate";
import { Base_Url } from "../../../config/variables";
import { ToastContainer } from "react-toastify";
import Loader from "../../../helper/Loader/Loader";

const Animals = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("user_token");
  const [petsData,setpetsData] = useState([]);
  const [getpetloader,setgetpetloader] = useState(false);
  const userFirstname = localStorage.getItem("user_firstname");
  const userLastname = localStorage.getItem("user_lastname");
  const getAnimals = ()=>{
    setgetpetloader(true)
   AXIOS_GET(GET_PETS_URL,token)
   .then(res=>{
   setpetsData(res.data)
    setgetpetloader(false)
   })
   .catch((error)=>{
    setgetpetloader(false)
   })

  }
  useEffect(()=>{
    getAnimals();
  },[])
  
  return (
    <div className="content-container customer-content-container">
      <ToastContainer/>
      <h1 className="welcome-title">
        Bienvenue <span>{userFirstname&&userFirstname} {userLastname&&userLastname}</span>
      </h1>
      <h1 className="page-name">Mes animaux en un clic</h1>
      <h1 className="page-name sm-page-name">Mes animaux</h1>
      <div className="page-image-container">
      {
          petsData.length>0 ?
          petsData.map(pet=>{
            return  (
            <div className="pet-data" onClick={() => navigate(`${pet._id}`)}>
              <div className="img-holder">
                <img
                  src={`${Base_Url}/${pet.picture}`}
                  alt=""
                />
              </div>
              <span>{pet.name}</span>
            </div>
          )
          })
            :
            getpetloader &&
            <div className="pet-data">
              <div className="img-holder">
                <Loader />
              </div>
              
            </div>
        }
       
        <div className="pet-data" onClick={() => navigate("add")}>
          <div className="img-holder">
            <img src={addIcon} className="addIcon" alt="" />
          </div>
          <span>
            <Translate Word="page.ajouteanimaux" />
          </span>
        </div>
      </div>
      <p className="remarks">
        Vous pouvez aussi retrouver le détails et modifier la fiche de chaque
        animal en cliquant sur sa photo ou dans la barre latérale, dans la
        rubrique Mes animaux.
      </p>
    </div>
  );
};

export default Animals;
