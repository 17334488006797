import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import PetsIcon from "@mui/icons-material/Pets";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Map from "../../common/Map";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import Translate from "../../../../config/Translate";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

export const Upcoming = ({ item, noIci = true, itemNumber=0 }) => {
  const navigate = useNavigate();

  const formatTime = (hour) => {
    const formattedHour = String(hour).padStart(2, "0");
    const formattedMinutes = "00";
    return `${formattedHour}:${formattedMinutes}`;
  };
  const dateToreadable = (date) => {
    const inputString = date.split("-")
    const year = inputString[0]
    const month = inputString[1]
    const day = inputString[2]
    return `${day}-${month}-${year}`
    } 

  const TimeDisplay = (hour) => {
    return formatTime(hour);
  };

  return (
    <div className="rendezvous">
      {itemNumber === 0 && (
        <h1 className="rendezvous-title">
          <Translate Word={"page.prochainrendezvous"} />
        </h1>
      )}

      <div
        className="flex-container rendezvous-detail"
        style={{ position: "relative" }}
      >
        <div className="detail" style={{ flex: 1, alignSelf: "self-start" }}>
          <ul>
            <li className="height-35">
              <PersonIcon />
               
              <span>
                 Dr. {" "}
                {item.provider.firstname} {item.provider.lastname}
              </span>
            </li>
            <li className="height-35">
              <CalendarMonthIcon />
              <span>
                {item?.date && dateToreadable(new Date(item?.date).toISOString().slice(0, 10))}
              </span>
            </li>
            <li className="height-35">
              <ScheduleIcon />
              <span>{TimeDisplay(item.start.hours)}</span>
            </li>
            <li className="height-35">
              <PetsIcon />
              <span>
                {item.pet && item.pet.name
                  ? item.pet.name
                  : "Pas d’animal enregistré"}
              </span>
            </li>
          </ul>
          <button
            className="prev-rendezvous-btn upbtn"
            onClick={() => navigate("/search?clinicId=" + item?.clinic?._id)}
            style={{ bottom: "70px" }}
          >
            {/* <SkipNextIcon /> */}
            {/* <VisibilityIcon /> */}
            <span>Prendre de nouveau rdv ici</span>
          </button>
          <button
            className="prev-rendezvous-btn"
            onClick={() => navigate("/search")}
          >
            {/* <SkipNextIcon /> */}
            {/* <VisibilityIcon /> */}
            <span>Prendre un autre rdv ailleurs</span>
          </button>
        </div>
        <div
          className="detail"
          style={{ flex: 1.3, position: "relative", alignSelf: "self-start" }}
        >
          <ul>
            <li className="height-35">
              <LocationOnIcon />
              <div className="address_map_info">
                <span> {item.clinic?.facilityName} </span>
                <span> {item.clinic?.address} </span>
                <span> {item.clinic?.zipcode} </span>
                <span> {item.clinic?.city} </span>
              </div>
            </li>
            {/* <li className="prev-rendezvous" onClick={()=>navigate('/customer/dashboard/rendezvous')}>
                <VisibilityIcon />
                <span>
                  Prendre de nouveau rdv ici
                </span>
              </li> */}
          </ul>
          <div style={{ marginTop: "26px" }}>
            <Map
              height="300px"
              latitude={item?.clinic?.geolocation?.coordinates[1]}
              longitude={item?.clinic?.geolocation?.coordinates[0]}
            />
          </div>

          <button
            className="prev-rendezvous-btn mobo-btn  upbtn"
            onClick={() => navigate("/search?clinicId=" + item?.clinic?._id)}
          >
            {/* <SkipNextIcon /> */}
            {/* <VisibilityIcon /> */}
            <span>Prendre de nouveau rdv ici</span>
          </button>
          <button
            className="prev-rendezvous-btn mobo-btn"
            onClick={() => navigate("/search")}
          >
            {/* <SkipNextIcon /> */}
            {/* <VisibilityIcon /> */}
            <span>Prendre un autre rdv ailleurs</span>
          </button>
        </div>
      </div>
    </div>
  );
};
