import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HomeContainer from "../../../components/client/container/HomeContainer";
import { selectLanguage } from "../../../features/LanguageSlice";
import "../../../styles/search/General.scss";
import { SearchCard } from "./SearchCard";
import { useLocation } from "react-router-dom";
import {
  selectInactiveClinics,
  selectPagination,
  selectQueryParams,
  selectSearchResult,
  setInactiveClinics,
  setPagination,
  setSearchedClinics,
} from "../../../features/SearchSlice";
import { AXIOS_GET } from "../../../config/axios";
import { GET_SEARCH_CLINICS_NEAREST_URL, GET_SEARCH_CLINICS_NEAREST_WITH_SLOTS_URL } from "../../../helper/Url";
import { SearchSlotCard } from "./SearchSlotsCard";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Loader from "../../../helper/Loader/Loader";

export const General = () => {
  const languageValue = useSelector(selectLanguage);
  const [place, setPlace] = React.useState("");
  const searchData = useSelector(selectSearchResult).payload;
  const bookPage = useRef();
  const attemptCount = useRef(0);
  const pagination = useSelector(selectPagination).payload;
  const searchParams = useSelector(selectQueryParams).payload;
  const [page, setPage] = useState(pagination?.currentPage || 1);
  const [loading, setLoading] = useState(false);
  const inactiveClinics = useSelector(selectInactiveClinics).payload;

  useEffect(() => {
    if (window.innerWidth > 800) {
      window.scrollTo({ top: 500, behavior: "smooth" });
    } else {
      window.scrollTo({ top: 325, behavior: "smooth" });
    }
  }, [searchData]);

  // Get the location object using the useLocation hook
  const location = useLocation();

  // Parse the search string to get the query parameters
  const queryParams = new URLSearchParams(location.search);

  // Get the clinicId parameter from the query parameters
  const ClinicIdSearch = queryParams.get("clinicId");
  const dispatch = useDispatch();

  let Title =
    languageValue === "En"
      ? "Trouvez un vétérinaire en un clic"
      : "Trouvez un vétérinaire en un clic";
  let Subtitle =
    languageValue === "En"
      ? "Prendre rendez-vous en ligne"
      : "Prendre rendez-vous en ligne";

  // get place from local storage
  useEffect(() => {
    if (localStorage.getItem("searchCodePostal")) {
      setPlace(localStorage.getItem("searchCodePostal"));
    }
  }, [localStorage.getItem("searchCodePostal")]);

  const handleSubmit = (newPage) => {
    AXIOS_GET(
      GET_SEARCH_CLINICS_NEAREST_WITH_SLOTS_URL +
        `?latitude=${searchParams?.latitude || 0}&longitude=${searchParams?.longitude || 0}&species=${searchParams?.species || ""}&searchText=${searchParams?.searchText || ""}&page=${newPage || page}`
    )
      .then((res) => {
        dispatch(setSearchedClinics(res.data.clinics));
        dispatch(setInactiveClinics(res.data.inactiveClinics));
        dispatch(setPagination({
          currentPage: newPage || page,
          totalPages: res.data.totalPages
        }));
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const handlePageChange = (newPage) => {
    setLoading(true);
    setPage(newPage);
    handleSubmit(newPage);
  };

  // check if searchData is empty or null and submit the form

  useEffect(() => {
    if (
      searchData === null ||
      searchData === undefined ||
      searchData.length === 0
    ) {
      if (attemptCount.current < 2) {
        handleSubmit();
        attemptCount.current += 1;
      }
    }
  }, [searchData]);

  return (
    <div className="change-container">
      <HomeContainer Title={Title} Subtitle={Subtitle} display_map="false">
        <div className="search-container" ref={bookPage}>
          <div className="intro">
            <h3>{searchData?.length > 0 ? "Prenez rendez-vous en ligne avec un vétérinaire" : "Autres cliniques vétérinaires"}</h3>
          </div>
          <SearchCard ClinicIdSearch={ClinicIdSearch} attemptCount={attemptCount} />
          {/* width 100 on mobile */}
          <div className="w-full md:w-80%" style={{ padding: "30px"}}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>{loading && <Loader />}</div>
            <div className="flex justify-center mt-4 space-x-2">
              <button
              className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 disabled:opacity-50"
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1 || loading}
            >
              <IoIosArrowBack />
            </button>
            {Array.from({ length: pagination?.totalPages }, (_, i) => i + 1)
              .slice(Math.max(0, page - 4), Math.min(pagination?.totalPages, page + 3))
              .map((pageNumber) => (
                <span
                  key={pageNumber}
                  className={`px-4 py-2 mx-1 rounded-full cursor-pointer ${
                    pageNumber === page ? 'bg-[#2CB5B2] text-white' : 'bg-gray-200'
                  }`}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </span>
              ))}
            <button
              className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 disabled:opacity-50"
              onClick={() => handlePageChange(page + 1)}
              disabled={page === pagination?.totalPages || loading}
            >
                <IoIosArrowForward />
              </button>
            </div>
          </div>
        </div>
      </HomeContainer>
    </div>
  );
};
