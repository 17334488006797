import React, { useState, useEffect } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

const TimetableSlots = ({ 
  isLoading,
  slots = [],
  id,
  slotInterval,
  handlebooking,
  operatingHours,
  weekTimeTable,
  onretrieve = null,
}) => {
  const [showAllSlots, setShowAllSlots] = useState(false);
  const [weekday, setweekday] = useState(0);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [displaySlots, setDisplaySlots] = useState([]);
  const [daysPerSet, setDaysPerSet] = useState(5);

  // Function to detect if the device is mobile
  const isMobileDevice = () => {
    return window.innerWidth <= 768; // You can adjust the width threshold as needed
  };

  useEffect(() => {
    const updateDaysPerSet = () => {
      const daysToShow = isMobileDevice() ? 3 : 5; // Show 3 days on mobile, 5 otherwise
      setDaysPerSet(daysToShow);
    };

    updateDaysPerSet(); // Initial check

    window.addEventListener('resize', updateDaysPerSet); // Update on resize

    return () => {
      window.removeEventListener('resize', updateDaysPerSet); // Cleanup on unmount
    };
  }, []); // Empty dependency array to run only on mount and unmount

  useEffect(() => {
    if (onretrieve != null) {
      setDisplaySlots(onretrieve);
      setDaysPerSet(Math.min(11, onretrieve.length, daysPerSet));
    } else {
      setDisplaySlots(slots);
      setDaysPerSet(Math.min(11, slots.length, daysPerSet));
    }
  }, [slots, onretrieve, daysPerSet]);

  // Handle time slot selection
  const picktime = (date, time, startTimeObj, endTimeObj, day, e) => {
    setSelectedSlot({ date, time });
    handlebooking(id, date.slice(0, 10), time, startTimeObj, endTimeObj, day);
  };

  // Navigation functions
  const handlePrevWeek = () => {
    const visibleDaysArray = visibleDays();
    const firstVisibleDate = new Date(visibleDaysArray[0].date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    if (weekday <= 0) {
      weekTimeTable(id, today.toISOString().slice(0, 10));
      setweekday(0);
      return;
    }

    firstVisibleDate.setDate(firstVisibleDate.getDate() - (daysPerSet + 1));
    
    if (firstVisibleDate < today) {
      weekTimeTable(id, today.toISOString().slice(0, 10));
      setweekday(0);
      return;
    }

    weekTimeTable(id, firstVisibleDate.toISOString().slice(0, 10));
    setweekday(weekday - daysPerSet);
  };

  const handleNextWeek = () => {
    const visibleDaysArray = visibleDays();
    const lastVisibleDate = visibleDaysArray[visibleDaysArray.length - 1].date;
    weekTimeTable(id, lastVisibleDate);
    setweekday(weekday + daysPerSet);
  };

  // Helper function to get only visible days, accounting for potentially large gaps
  const visibleDays = () => {
    const visibleCount = Math.min(daysPerSet, displaySlots.length);
    const days = displaySlots.slice(0, visibleCount);
    
    // If we have fewer than daysPerSet days, pad with empty days
    while (days.length < daysPerSet) {
      const lastDate = days.length > 0 
        ? new Date(days[days.length - 1].date)
        : new Date();
      lastDate.setDate(lastDate.getDate() + 1);
      
      days.push({
        date: lastDate.toISOString().slice(0, 10),
        slots: [], // Empty slots array
        dayIndex: days.length
      });
    }
    
    return days;
  };

  const getDayName = (date) => {
    return new Date(date).toLocaleDateString('fr-FR', { weekday: 'long' });
  };

  const getFormattedDate = (date) => {
    const formatted = new Date(date).toLocaleDateString('fr-FR', { 
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
    return formatted.replace('.', ''); // Remove period after month abbreviation
  };

  // Helper function to check if a time is within operating hours
  const isWithinOperatingHours = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const timeInMinutes = hours * 60 + minutes;
    const startInMinutes = operatingHours.startTime.hours * 60 + operatingHours.startTime.minutes;
    const endInMinutes = operatingHours.endTime.hours * 60 + operatingHours.endTime.minutes;
    
    return timeInMinutes >= startInMinutes && timeInMinutes <= endInMinutes;
  };

  // Modified getAllTimeSlots to generate times based on operating hours
  const getAllTimeSlots = () => {
    const allTimes = new Set();
    
    // Generate all possible time slots within operating hours
    let currentHour = operatingHours.startTime.hours;
    let currentMinute = operatingHours.startTime.minutes;
    
    while (
      currentHour < operatingHours.endTime.hours || 
      (currentHour === operatingHours.endTime.hours && currentMinute <= operatingHours.endTime.minutes)
    ) {
      const timeString = `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
      allTimes.add(timeString);
      
      // Increment by your time slot interval (e.g., 20 minutes)
      currentMinute += slotInterval;
      if (currentMinute >= 60) {
        currentHour += 1;
        currentMinute = 0;
      }
    }

    return Array.from(allTimes).sort();
  };

  // Helper to find slot for a specific time in a day
  const findSlotForTime = (daySlots, timeString) => {
    // If day is missing or has no slots, return null to show "-"
    if (!daySlots || !daySlots?.slots || !Array.isArray(daySlots.slots)) return null;
    
    // Check if this is today
    const today = new Date();
    const slotDate = new Date(daySlots.date);
    const isToday = slotDate.toDateString() === today.toDateString();
    
    // If it's today, check if the time has passed
    if (isToday) {
      const [hours, minutes] = timeString.split(':').map(Number);
      const slotTime = new Date();
      slotTime.setHours(hours, minutes, 0, 0);
      
      if (slotTime < today) {
        return null; // This will make the slot show "-"
      }
    }
    
    const slot = daySlots.slots.find(slot => {
      const slotTime = `${slot.start.hours.toString().padStart(2, '0')}:${slot.start.minutes.toString().padStart(2, '0')}`;
      return slotTime === timeString;
    });

    return slot ? { ...slot, status: true } : null;
  };

  // Helper to get visible slots based on showAllSlots state
  const getVisibleTimeSlots = (timeSlots) => {
    const filteredSlots = timeSlots.filter(timeString => isWithinOperatingHours(timeString));
    return showAllSlots ? filteredSlots : filteredSlots.slice(0, 5);
  };

  return (
    <div className="relative w-full max-w-2xl mx-auto flex flex-col items-center p-2 md:p-4">
      {isLoading ? (
        <div className="w-full animate-pulse">
          {/* Header skeleton - Days */}
          <div className="flex flex-1 gap-1 mb-4 justify-center">
            {[...Array(daysPerSet)].map((_, index) => (
              <div key={index} className="flex-1 min-w-[100px] max-w-[100px] text-center">
                <div className="mb-3">
                  {/* Day name skeleton */}
                  <div className="h-4 bg-gray-200 rounded w-16 mx-auto mb-2"></div>
                  {/* Date skeleton */}
                  <div className="h-3 bg-gray-200 rounded w-20 mx-auto"></div>
                </div>
              </div>
            ))}
          </div>

          {/* Time slots skeleton */}
          <div className="space-y-1.5">
            {[...Array(5)].map((_, rowIndex) => (
              <div key={rowIndex} className="flex gap-1 justify-center">
                {[...Array(daysPerSet)].map((_, colIndex) => (
                  <div
                    key={colIndex}
                    className="h-[32px] border rounded-[5px] flex-1 min-w-[100px] max-w-[100px] bg-gray-200"
                  ></div>
                ))}
              </div>
            ))}
          </div>

          {/* "See more" button skeleton */}
          <div className="pt-4 flex justify-center">
            <div className="h-4 bg-gray-200 rounded w-32"></div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-1 gap-2 justify-center relative">
            {visibleDays().map((day, index) => (
              <div key={day.date} className="flex-1 min-w-[100px] max-w-[100px] text-center">
                <div className="mb-3 relative">
                  {index === 0 && (
                    <button 
                      className="absolute left-[-30px] top-1/2 transform -translate-y-1/2 text-[#2cb5b2] p-2"
                      onClick={handlePrevWeek}
                    >
                      <KeyboardArrowLeftIcon sx={{ fontSize: 34 }} />
                    </button>
                  )}
                  {index === daysPerSet - 1 && (
                    <button 
                      className="absolute right-[-30px] top-1/2 transform -translate-y-1/2 text-[#2cb5b2] p-2"
                      onClick={handleNextWeek}
                    >
                      <KeyboardArrowRightIcon sx={{ fontSize: 34 }} />
                    </button>
                  )}
                  <div className="text-[14px] font-bold capitalize mt-2">
                    {getDayName(day.date)}
                  </div>
                  <div className="text-[12px] text-gray-500">
                    {getFormattedDate(day.date)}
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          <div className="space-y-1.5">
            {getVisibleTimeSlots(getAllTimeSlots()).map(timeString => (
              <div key={timeString} className="flex gap-1 justify-center">
                {visibleDays().map((day) => {
                  const slot = findSlotForTime(day, timeString);
                  return (
                    <div
                      key={`${day.date}-${timeString}`}
                      className={`
                        time-slot
                        h-[32px]
                        border rounded-[5px]
                        flex items-center justify-center
                        cursor-pointer
                        flex-1 min-w-[100px] max-w-[100px]
                        ${slot?.status 
                          ? selectedSlot && selectedSlot.date === day.date && selectedSlot.time === timeString
                            ? 'border-[#2cb5b2] bg-[#2cb5b2] text-white'
                            : 'border-[#2cb5b2] text-[#2cb5b2] hover:bg-[#2cb5b2] hover:text-white'
                          : 'border-gray-200 text-gray-300'
                        }
                      `}
                      onClick={(e) => 
                        slot?.status && picktime(
                          day.date,
                          timeString,
                          slot.start,
                          slot.end,
                          day.dayIndex,
                          e
                        )
                      }
                    >
                      <span className="text-[13px] font-bold">
                        {slot?.status ? timeString : '-'}
                      </span>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          
          <div className="more pt-4 w-full flex justify-center">
            {!showAllSlots && (
              <span 
                className="text-[#2cb5b2] font-bold flex items-center justify-center cursor-pointer"
                onClick={() => setShowAllSlots(true)}
              >
                Voir plus d'horaires <KeyboardArrowDown sx={{ fontSize: 20 }} />
              </span>
            )}
            {showAllSlots && (
              <span 
                className="text-[#2cb5b2] font-bold flex items-center justify-center cursor-pointer"
                onClick={() => setShowAllSlots(false)}
              >
                Voir moins d'horaires <KeyboardArrowDown sx={{ transform: 'rotate(180deg)' }} />
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TimetableSlots;