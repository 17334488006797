import React from "react";
import "../../styles/client/home/Home.scss";
import left_image from "../../assets/Images/oneclic.vet_illustration_left.png";
import right_image from "../../assets/Images/oneclic.vet_illustration_right.png";
import bg_section_image from "../../assets/Images/oneclic.vet_bg-picto.png";
import Fade from "react-reveal/Fade";
import Translate from "../../config/Translate";
import { useNavigate } from "react-router-dom";

const Inscription = () => {
  const navigate = useNavigate();
  return (
    <div className="home_container">
      <div
        className="bg_container"
      >
        <img src={bg_section_image} alt="" srcset="" />
      </div>
      <div className="home_content">
      <div className="sub_content content_right">
          <div className="content_img">
            <Fade left>
              <img src={right_image} alt="" />
            </Fade>
          </div>
          <h1 className="text-2xl font-bold">
          Vous êtes <span className="main-color">un particulier</span>
          </h1>
          <p>
          <strong>Prenez rendez-vous en ligne avec votre vétérinaire 24h/24 et 7j/7 en quelques clics !</strong>
          <br />
          Depuis n'importe quel appareil, prenez rendez-vous pour votre animal avec votre vétérinaire ou le vétérinaire disponible le plus proche de chez vous.
           <br />
          <br/> 

C'est simple, sécurisé et entièrement gratuit.
          </p>
          <button  onClick={() => navigate("/preclientregister")}>
          INSCRIPTION CLIENT
          </button>
        </div>
      <div className="sub_content content_left">
          <div className="content_img">
            <Fade right>
              <img src={left_image} alt="" />
            </Fade>
          </div>
          <h1 className="text-2xl font-bold">
             Vous êtes <span className="main-color">vétérinaires ?</span>
          </h1>
          <p>
          <strong>Inscrivez-vous dès maintenant et simplifiez-vous la vie !</strong><br />
           Profitez d'une solution simple, intuitive et rapide pour vous connecter à vos clients et optimiser votre temps.
            <br />
            <br/> 
           Découvrez notre plateforme innovante et notre nouvelle offre sans abonnement ni engagement, conçue spécialement pour la profession vétérinaire.
          </p>
          {/* go to https://info.oneclic.vet/veterinaire-contactez-nous/#contact */}
          <button onClick={() => window.open("https://info.oneclic.vet/veterinaire-contactez-nous/#contact", "_blank")}>
          INSCRIPTION VÉTÉRINAIRE
          </button>
        </div>
     
       
        
      </div>
    </div>
  );
};

export default Inscription;
