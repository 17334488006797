import NotActiveClinicInfo from "./NotActiveClinicInfo";

const NotActiveTimetable = ({clinic}) => {
  return (
    <div className="w-full h-full flex items-center justify-between" style={{ border: "1px solid #2CB5B2" }}>
        <NotActiveClinicInfo clinic={clinic} />
    </div>
  );
};

export default NotActiveTimetable;