import React, { useState, useEffect } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";

function PlaceAutocomplete({
  placeholder = "Entrez un emplacement",
  updateLocation,
  value,
  onChange,
  setCordinate,
  name
}) {
  const [place, setPlace] = useState(null);
  const [inputValue, setInputValue] = useState(value || '');

  useEffect(() => {
    if (place) {
      getGeolocation();
    }
  }, [place]);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const onLoad = (autocomplete) => {
    autocomplete.addListener("place_changed", () => {
      const selectedPlace = autocomplete.getPlace();
      setPlace(selectedPlace);
      
      // Update input value when place is selected
      if (selectedPlace.formatted_address) {
        setInputValue(selectedPlace.formatted_address);
      }
    });
  };

  const getGeolocation = () => {
    if (place && place.geometry && place.geometry.location) {
      const { lat, lng } = place.geometry.location;
      if (updateLocation) {
        updateLocation(
          lat(), 
          lng(), 
          place.formatted_address,
          place.address_components
        );
      }
      if (setCordinate) {
        setCordinate({
          lat: lat(),
          lng: lng(),
          place: place.formatted_address,
        });
      }
      return `Latitude: ${lat()}, Longitude: ${lng()}`;
    }
    return "no data";
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    
    // Create synthetic event with name property
    const syntheticEvent = {
      target: {
        name: name,
        value: newValue
      }
    };
    
    if (onChange) {
      onChange(syntheticEvent);
    }
  };

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={getGeolocation}
      options={{ componentRestrictions: { country: "ch" } }}
      className="w-full"
    >
      <input
        type="text"
        autoComplete="off"
        onChange={handleInputChange}
        value={inputValue}
        className="border_purple text-black bg-white w-full"
        placeholder={placeholder}
        name={name}
        style={{
          width: "100%", fontSize: "14px"
        }}
      />
    </Autocomplete>
  );
}

export default PlaceAutocomplete;
