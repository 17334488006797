import React, { useEffect, useState } from "react";
import addIcon from "../../../assets/Icons/addIcon.svg";
import { useNavigate } from "react-router-dom";
import Translate from "../../../config/Translate";
import { AXIOS_GET } from "../../../config/axios";
import { GET_APPOINTMENT_CLINIC_URL, GET_PETS_URL, GET_SINGLE_CLIENTS_URL } from "../../../helper/Url";
import { Base_Url } from "../../../config/variables";
import ErrorToast from "../../../helper/Message/ErrorToast";
import { Upcoming } from "./Rendezvous/Upcoming";
import Loader from "../../../helper/Loader/Loader";

const Board = () => {
  const navigate = useNavigate();
  const userFirstname = localStorage.getItem("user_firstname");
  const userLastname = localStorage.getItem("user_lastname");
  const token = localStorage.getItem("user_token");
  const [petsData, setpetsData] = useState([]);
  const [Allappointments, setAllappointments] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [getpetloader, setgetpetloader] = useState(false);
  const id = localStorage.getItem("user_id");

  // get all animals
  const getAnimals = () => {
    setgetpetloader(true);
    AXIOS_GET(GET_PETS_URL, token)
      .then((res) => {
        setgetpetloader(false);
        setpetsData(res.data);
      })
      .catch((error) => {
        setgetpetloader(false);
      });
  };

  // get all appointments
  const getAllAppontments = () => {
    AXIOS_GET(GET_APPOINTMENT_CLINIC_URL, token)
      .then((res) => {
        setAllappointments(res?.data);
      })
      .catch((error) => {
        ErrorToast(error);
      });
  };



  // Initialize
  useEffect(() => {
    getAllAppontments();
    getAnimals();
  }, []);

  // filter
  useEffect(() => {
    const fil = Allappointments.filter((appointment) => {
      return appointment.status.toLowerCase() === "pending";
    });
    setFiltered(fil);
  }, [Allappointments]);

  return (
    <div className="content-container customer-content-container">
      <h1 className="welcome-title" style={{ fontSize: "20px" }}>
        <Translate Word="page.Bienvenue" />{" "}
        <span>
          {userFirstname && userFirstname} {userLastname && userLastname}
        </span>
      </h1>
      <h1 style={{ fontSize: "30px" }} className="page-name">
        Mon espace en un clic
      </h1>
      <h1 className="page-name sm-page-name">
        <Translate Word="page.myanimaux" />
      </h1>
      <div className="page-image-container">
        {petsData.length > 0
          ? petsData.map((pet) => {
              return (
                <div
                  className="pet-data"
                  onClick={() =>
                    navigate(`/customer/dashboard/animals/${pet._id}`)
                  }
                >
                  <div className="img-holder">
                    <img
                      src={Base_Url + `/${pet.picture}`
                      }
                      alt=""
                    />
                  </div>
                  <span>{pet.name}</span>
                </div>
              );
            })
          : getpetloader && (
              <div className="pet-data">
                <div className="img-holder">
                  <Loader />
                </div>
              </div>
            )}

        <div
          className="pet-data"
          onClick={() => navigate("/customer/dashboard/animals/add")}
        >
          <div className="img-holder">
            <img src={addIcon} className="addIcon" alt="" />
          </div>
          <span>
            <Translate Word="page.ajouteanimaux" />
          </span>
        </div>
      </div>
      <p className="remarks">
        <Translate Word={"page.animaldetailmessage"} />
      </p>

      {filtered.length > 0 ? (
        filtered.map(
          (item, index) =>
            index === filtered.length - 1 && (
              <Upcoming
                key={index}
                itemNumber={index}
                item={item}
                noIci={false}
              />
            )
        )
      ) : (
        <div className="rendezvous">
          <h1 className="rendezvous-title">
            <Translate Word={"page.prochainrendezvous"} />
          </h1>
          <div className="strong">Vous n’avez aucun rendez-vous.</div>
          <button
            className="btn black poppins"
            onClick={() => navigate("/search")}
          >
            PRENDRE UN RENDEZ-VOUS
          </button>
        </div>
      )}
    </div>
  );
};

export default Board;
