import React, { useEffect, useRef, useState } from "react";
import { GeneralSearchCard } from "./GeneralSearchCard";
import { selectInactiveClinics, selectPagination, selectQueryParams, selectSearchResult, setInactiveClinics, setPagination, setSearchedClinics } from "../../../features/SearchSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../helper/Loader/Loader";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { AXIOS_GET } from "../../../config/axios";
import { GET_SEARCH_CLINICS_NEAREST_WITH_SLOTS_URL } from "../../../helper/Url";
import { Navigate, useNavigate } from "react-router-dom";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export const SearchCard = ({ ClinicIdSearch, attemptCount }) => {
  const searchData = useSelector(selectSearchResult).payload;
  const dispatch = useDispatch();
  const inactiveClinics = useSelector(selectInactiveClinics).payload;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("searchData", "searchData");
  }, []);

  return (
    <div>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {loading ? (
        <Loader />
      ) : searchData?.length > 0 ? (
        <>
          {searchData.map((item, index) => (
            <GeneralSearchCard
              ClinicIdSearch={ClinicIdSearch}
              item={item}
              index={index}
            />
          ))}
          {inactiveClinics.length > 0 &&  (<div className="w-[80%]" style={{ margin: "auto" }}><p className="text-center text-8xl font-bold mt-2">Autres cliniques vétérinaires</p></div>)}
          {inactiveClinics.map((item, index) => (
            <GeneralSearchCard
              ClinicIdSearch={ClinicIdSearch}
              item={item}
              isInactive={false}
              index={index}
            />
          ))}
        </>
      ) : (
        <div>
          {attemptCount.current >= 2 ? (
            <div style={{ textAlign: 'center', padding: '20px', fontSize: '18px' }}>
              {inactiveClinics.map((item, index) => (
                <GeneralSearchCard
                  ClinicIdSearch={ClinicIdSearch}
                  item={item}
                  isInactive={false}
                  index={index}
                />
              ))}
            </div>
          ) : (
            <div style={{ display: "flex", gap: 8, marginBottom: "50px" }}>
              <div style={{ flex: 3 }}>
                <SkeletonTheme color="#f5f5f5" highlightColor="#dbdbdb">
                  <Skeleton height={500} />
                </SkeletonTheme>
              </div>
              <div style={{ flex: 1 }}>
                <SkeletonTheme color="#f5f5f5" highlightColor="#dbdbdb">
                  <Skeleton height={500} />
                </SkeletonTheme>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
