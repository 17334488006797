// Get environment from process.env
const environment = process.env.REACT_APP_ENV || 'local';

// Define URLs for different environments
export const Base_Url = (() => {
  switch (environment) {
    case 'production':
      return process.env.REACT_APP_BASE_URL_PROD;
    case 'test':
      return process.env.REACT_APP_BASE_URL_TEST;
    default:
      return process.env.REACT_APP_BASE_LOCAL;
  }
})();



